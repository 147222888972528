import UAParser from 'ua-parser-js';

import { Injectable } from '@angular/core';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { BrowserServiceBase, IBrowserData } from '@profis-engineering/pe-ui-common/services/browser.common';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { environment } from '../../environments/environment';
import { CommonCodeListService } from './common-code-list.service';
import { LoggerService } from './logger.service';
import { OfflineService } from './offline.service';

const padding = '=';
const chrTable = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
const binTable = [
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63,
    52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, 0, -1, -1,
    -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
    15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1,
    -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1
];

@Injectable({
    providedIn: 'root'
})
export class BrowserService extends BrowserServiceBase {
    public isOfflineOnLine: boolean;
    private scrollbarWidthPvt: number;
    private parser?: UAParser;
    private userAgentData?: NavigatorUAData;
    private browserData: IBrowserData;

    constructor(
        private readonly offlineService: OfflineService,
        private readonly loggerService: LoggerService,
        private readonly commonCodeListService: CommonCodeListService
    ) {
        super();
        this.onLineChanged = this.onLineChanged.bind(this);

        this.isOfflineOnLine = !this.offlineService.isOffline || navigator.onLine;
        window.addEventListener('online', this.onLineChanged, false);
        window.addEventListener('offline', this.onLineChanged, false);

        this.initBrowserData();
    }

    public get scrollbarWidth() {
        if (this.scrollbarWidthPvt == null) {
            this.scrollbarWidthPvt = this.calculateScrollbarWidth();
        }

        return this.scrollbarWidthPvt;
    }

    public encodeB64(str: string) {
        let result = '';
        const bytes = this.utf8Encode(str);
        const length = bytes.length;
        let i = 0;

        // Convert every three bytes to 4 ascii characters.
        for (; i < (length - 2); i += 3) {
            result += chrTable[bytes[i] >> 2];
            result += chrTable[((bytes[i] & 0x03) << 4) + (bytes[i + 1] >> 4)];
            result += chrTable[((bytes[i + 1] & 0x0f) << 2) + (bytes[i + 2] >> 6)];
            result += chrTable[bytes[i + 2] & 0x3f];
        }

        // Convert the remaining 1 or 2 bytes, pad out to 4 characters.
        if (length % 3) {
            i = length - (length % 3);
            result += chrTable[bytes[i] >> 2];
            if ((length % 3) === 2) {
                result += chrTable[((bytes[i] & 0x03) << 4) + (bytes[i + 1] >> 4)];
                result += chrTable[(bytes[i + 1] & 0x0f) << 2];
                result += padding;
            } else {
                result += chrTable[(bytes[i] & 0x03) << 4];
                result += padding + padding;
            }
        }

        return result;
    }

    public decodeB64(data: string) {
        let value = 0;
        let code = 0;
        let idx = 0;
        const bytes: number[] = [];
        let leftbits = 0; // number of bits decoded, but yet to be appended
        let leftdata = 0; // bits decoded, but yet to be appended

        // Convert one by one.
        for (; idx < data.length; idx++) {
            code = data.charCodeAt(idx);
            value = binTable[code & 0x7F];

            if (-1 === value) {
                // Skip illegal characters and whitespace
                this.loggerService.log('BrowserService.decodeB64: Illegal characters (code=' + code + ') in position ' + idx, LogType.warn);
            } else {
                // Collect data into leftdata, update bitcount
                leftdata = (leftdata << 6) | value;
                leftbits += 6;

                // If we have 8 or more bits, append 8 bits to the result
                if (leftbits >= 8) {
                    leftbits -= 8;
                    // Append if not padding.
                    if (padding !== data.charAt(idx)) {
                        bytes.push((leftdata >> leftbits) & 0xFF);
                    }
                    leftdata &= (1 << leftbits) - 1;
                }
            }
        }

        // If there are any bits left, the base64 string was corrupted
        if (leftbits) {
            this.loggerService.log('BrowserService.decodeB64: Corrupted base64 string', LogType.error);
            return null;
        }

        return bytes;
    }

    public base64toBlob(base64Data: string, contentType: string) {
        return new Blob([new Uint8Array(this.decodeB64(base64Data))], { type: contentType });
    }

    /**
     * Downloads a file or makes an appropriate action in the offline application.
     * @param blob - The file contents.
     * @param fileName - The file name.
     * @param storeInTemp - Should the file be stored in temp folder (makes no difference in on line application).
     * @param openAfterSave - Should the file be opened in the designated application after it is saved (makes no difference in on line application).
     */
    public async downloadBlob(blob: Blob, fileName: string, storeInTemp: boolean, openAfterSave: boolean, filePath?: string) {
        if (this.offlineService.isOffline) {
            return await this.offlineService.nativeFileSave(blob, fileName, storeInTemp, openAfterSave, filePath);
        }
        else {
            const url = URL.createObjectURL(blob);

            const anchor = document.createElement('a');
            document.body.appendChild(anchor);

            anchor.href = url;
            anchor.style.display = 'none';
            anchor.setAttribute('download', fileName);
            anchor.click();

            anchor.remove();

            // wait a bit before revoking the url
            setTimeout(() => {
                URL.revokeObjectURL(url);
            }, 5000);

            return url;
        }
    }

    public getRegion() {
        const regions = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        const language = window.navigator.language;

        // language format example: en-US or en
        const parts = language.split('-');
        const countryCode = (parts.length == 2 ? parts[1] : parts[0]).toLowerCase();

        // find region from last part of language
        const region = regions.find((r) => r.countryCode == countryCode);
        if (region != null) {
            return region;
        }

        // return international region
        return regions.find((r) => r.countryCode == 'int');
    }

    public toBase64(val: unknown) {
        if (val == null) {
            return null as string;
        }

        return this.encodeB64(JSON.stringify(val));
    }

    public fromBase64(value: string) {
        if (value == null) {
            return null;
        }

        return this.utf8Decode(this.decodeB64(value));
    }

    public getBrowserData() {
        return this.browserData;
    }

    public createHiddenIframe(): HTMLIFrameElement {
        const iframe = window.document.createElement("iframe");

        // shotgun approach
        iframe.style.visibility = "hidden";
        iframe.style.position = "fixed";
        iframe.style.left = "-1000px";
        iframe.style.top = "0";
        iframe.width = "0";
        iframe.height = "0";

        return iframe;
    }


    private calculateScrollbarWidth() {
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll';
        document.body.appendChild(outer);

        const inner = document.createElement('div');
        outer.appendChild(inner);

        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
        outer.parentNode.removeChild(outer);

        return scrollbarWidth;
    }

    private utf8Encode(str: string) {
        const bytes: number[] = [];
        let offset = 0;
        let length = 0;
        let char = '';

        str = encodeURI(str);
        length = str.length;

        while (offset < length) {
            char = str[offset];
            offset += 1;

            if ('%' !== char) {
                bytes.push(char.charCodeAt(0));
            } else {
                char = str[offset] + str[offset + 1];
                bytes.push(parseInt(char, 16));
                offset += 2;
            }
        }

        return bytes;
    }

    private utf8Decode(bytes: number[]) {
        const chars: string[] = [];
        let offset = 0;
        const length = bytes.length;
        let c = 0;
        let c2 = 0;
        let c3 = 0;

        while (offset < length) {
            c = bytes[offset];
            c2 = bytes[offset + 1];
            c3 = bytes[offset + 2];

            if (128 > c) {
                chars.push(String.fromCharCode(c));
                offset += 1;
            } else if (191 < c && c < 224) {
                chars.push(String.fromCharCode(((c & 31) << 6) | (c2 & 63)));
                offset += 2;
            } else {
                chars.push(String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)));
                offset += 3;
            }
        }

        return chars.join('');
    }

    private onLineChanged() {
        this.isOfflineOnLine = !this.offlineService.isOffline || navigator.onLine;
    }

    private initBrowserData() {
        this.getBrowserDataInternal().then((result) => {
            this.browserData = result;
        });
    }

    private async getBrowserDataInternal() {
        if (this.parser == null) {
            this.parser = new UAParser();
        }

        // Obtain data from user agent string
        const uai = this.parser.getResult();
        const retVal: IBrowserData = {
            browserName: uai.browser.name,
            browserVersion: uai.browser.version,

            osName: uai.os.name,
            osVersion: uai.os.version
        };

        // Override data obtained from user agent string
        await this.overrideBrowserData(retVal);

        retVal.browser = `${retVal.browserName} ${retVal.browserVersion}`.trim();
        retVal.os = `${retVal.osName} ${retVal.osVersion}`.trim();

        return retVal;
    }

    private async overrideBrowserData(browserData: IBrowserData) {
        if (this.offlineService.isOffline) {
            // Override browser data for PE Desktop
            browserData.browserName = 'HiltiPROFISEngineeringDesktop';
            browserData.browserVersion = environment.applicationVersion;
        }

        // Try overriding OS data from navigator.userAgentData
        if (this.userAgentData == null) {
            this.userAgentData = navigator.userAgentData;
        }

        if (this.userAgentData?.getHighEntropyValues == null) {
            return;
        }

        let uad: UADataValues;
        try {
            uad = await this.userAgentData?.getHighEntropyValues(['platformVersion']);
        }
        catch {
            this.loggerService.log('Could not determine OS using userAgentData.', LogType.warn);
        }

        if (uad?.platform && uad?.platformVersion) {
            browserData.osName = uad.platform;

            let osVersion = browserData.osVersion;
            if (browserData.osName.toLowerCase() === 'windows') {
                osVersion = this.getWindowsOsVersion(osVersion, uad);
            }
            else {
                osVersion = uad.platformVersion;
            }
            browserData.osVersion = osVersion;
        }
    }

    private getWindowsOsVersion(userAgentOsVersion: string, uad: UADataValues) {
        // Obtain Windows version
        // Source: https://learn.microsoft.com/en-us/microsoft-edge/web-platform/how-to-detect-win11
        const majorPlatformVersion = parseInt(uad.platformVersion.split('.')[0]);
        if (majorPlatformVersion >= 13) {
            return '11';        // Windows 11
        }
        if (majorPlatformVersion >= 1) {
            return '10';        // Windows 10
        }
        if (majorPlatformVersion >= 0) {
            return '7/8/8.1';   // Windows 7, 8 or 8.1
        }

        return userAgentOsVersion;
    }
}
