import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISubscriptionInfo } from '@profis-engineering/pe-ui-common/services/user.common';
import { OfflineServiceBase } from '@profis-engineering/pe-ui-common/services/offline.common';

import { environment } from '../../environments/environment';
import { urlPath } from '../module-constants';
import { ApiService } from './api.service';
import { SessionStorageService } from './session-storage.service';

export interface IAuthorizeResult {
    code: string;
    state: string;
}

/**
 * Logged in user to computer (domain user)
 */
export interface IOfflineUser {
    userId: string;
    username: string;
}

export interface ILogonResult {
    access_token: string;
    subscription_info: ISubscriptionInfo;
    externalUserId: string;
    externalUserName: string;
}

export interface ITokensResponse {
    access_token: string;
    expires_in: number;
    subscription_info: ISubscriptionInfo;
    token_type: string;
}

export interface ISetupLanguageRegistryInfo {
    language: string;
    countryCode: string;
}

@Injectable({
    providedIn: 'root'
})
export abstract class OfflineService extends OfflineServiceBase {
    constructor(
        protected apiService: ApiService,
        protected sessionStorageService: SessionStorageService
    ) {
        super();
    }

    public abstract loading(ignoreErrors?: boolean): Promise<void>;

    public abstract checkForUpdates(): Promise<void>;

    public abstract buildRedirectUri(path: string): string;

    /**
     * Opens or stores the file when run as native application (in Electron).
     * @param blob - The file to open or store.
     * @param fileName - Name of the file.
     * @param storeInTemp - Store the file temp folder, or show the save as dialog.
     * @param openAfterSave - Open the file after save.
     * @param filePath - Save the file to a already known local path - offline only.
     */
    public abstract nativeFileSave(
        blob: Blob,
        fileName: string,
        storeInTemp: boolean,
        openAfterSave: boolean,
        filePath?: string
    ): Promise<string>;

    public abstract getFilePath(file: File): string;

    public abstract getAppDataPath(): string;

    public abstract openImportDialog(callback?: (file: File) => void): void;

    public abstract getCurrentUser(): IOfflineUser;

    public abstract getSetupLanguageFromRegistry(): Promise<string>;

    public abstract parseSetupLanguage(source: string): ISetupLanguageRegistryInfo;

    public abstract loginUser(state: string, language: string): Promise<IAuthorizeResult>;

    public async getToken(code: string) {
        const body = new URLSearchParams();
        body.set('grant_type', 'authorization_code');
        body.set('code', code);
        body.set('client_id', environment.externalClientId);
        body.set('redirect_uri', this.buildRedirectUri(urlPath.authenticationCallback));

        if (environment.authentication == 'oauth2') {
            const verifier = this.sessionStorageService.get<string>('code_verifier');
            this.sessionStorageService.remove('code_verifier');
            body.set('code_verifier', verifier);
        }

        const url = `${environment.externalAuthenticationUrl}${environment.externalTokens}`;
        const request = new HttpRequest('POST', url, body.toString(), {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        });

        const response = await this.apiService.request<ITokensResponse>(request, { supressErrorMessage: true, skipRefreshToken: true });
        const resultData: ILogonResult = {
            access_token: response.body.access_token,
            subscription_info: response.body.subscription_info,
            externalUserId: response.body.subscription_info.UID,
            externalUserName: response.body.subscription_info.LogonID
        };

        return resultData;
    }
}
