import JSZip from 'jszip';
import { Injectable } from '@angular/core';
import { ExportService as ExportServiceCommon } from '@profis-engineering/pe-ui-common/services/export.common';
import { DocumentContentModel, ProjectGetProjectContentModel } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.Projects';
import { ModulesService } from './modules.service';
import { DocumentService } from './document.service';
import { LocalizationService } from './localization.service';
import { FeatureVisibilityService } from './feature-visibility.service';

@Injectable({
    providedIn: 'root'
})
export class ExportService extends ExportServiceCommon {
    constructor(
        private documentService: DocumentService,
        private localizationService: LocalizationService,
        private modulesService: ModulesService,
        private readonly featureVisibilityService: FeatureVisibilityService,
    ) {
        super();
    }

    private get isNewHomePage() {
        return this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
    }
    public async getProjectZip(projectId: string): Promise<Blob> {
        const data = await this.documentService.getProjectContent(projectId);

        // Create project hierarchy, document service returns all projects flat for old home page.
        const rootProjects = data.projects.filter(x => x.projectid == projectId);
        rootProjects.forEach(x => x.SubProjectList = data.projects.filter(y => y.parentprojectid == x.projectid));

        return this.generateProjectsContentZip(rootProjects);
    }

    public async getAllProjectsZip(): Promise<Blob> {
        const data = await this.documentService.getAllProjectsContent();

        // Create project hierarchy, document service returns all projects flat for old home page.
        const rootProjects = data.projects.filter(x => x.parentprojectid == null);
        const buildSubProjectList = (projects: ProjectGetProjectContentModel[], parentId: string): ProjectGetProjectContentModel[] => {
            return projects
                .filter(project => project.parentprojectid === parentId)
                .map(project => ({
                    ...project,
                    SubProjectList: buildSubProjectList(projects, project.projectid)
                }));
        };
        rootProjects.forEach(project => {
            project.SubProjectList = buildSubProjectList(data.projects, project.projectid);
        });

        return this.generateProjectsContentZip(rootProjects);
    }

    private async generateProjectsContentZip(projects: ProjectGetProjectContentModel[]) {
        // Flatten designs so that we can easily search them.
        const flattenDesigns = (projects: ProjectGetProjectContentModel[]): DocumentContentModel[] => {
            return projects.flatMap(project => [...project.DesignList, ...flattenDesigns(project.SubProjectList ?? [])]);
        };
        const flatDesigns = flattenDesigns(projects);

        // Group designs so that we can call convert per module.
        const groupDesigns = (designs: DocumentContentModel[]) => {
            const groups: Record<number, DocumentContentModel[]> = {};
            for (const design of designs) {
                if (groups[design.designtypeid] == null) {
                    groups[design.designtypeid] = [];
                }
                groups[design.designtypeid].push(design);
            }
            return groups;
        };
        const groupedDesigns = groupDesigns(flatDesigns);

        // Convert designs so that we can use converted content as .pe file.
        const convertDesigns = async (groups: Record<number, DocumentContentModel[]>) => {
            const promises: Promise<{ [id: string]: string }>[] = [];
            for (const designTypeStr in groups) {
                const designType = parseInt(designTypeStr);
                // In document service designs are stored as base64, we need them in text format, so do atob here.
                const designs = Object.fromEntries(groups[designType].map(x => [x.documentid, atob(x.filecontent)]));
                // Then call additional convert method for each module, whatever happens there is part of module.
                promises.push(this.modulesService.convertDesignsToPeFiles(designs, designType));
            }
            const converts = (await Promise.all(promises));
            return converts.reduce((p, c) => ({ ...p, ...c }), {});
        };
        const convertedDesigns = await convertDesigns(groupedDesigns);

        // Create zip with structure /project/subproject/subsubproject/.../design.pe
        const zip = new JSZip();
        const addProjectsToZip = (projects: ProjectGetProjectContentModel[], path = '') => {
            for (const project of projects) {
                const projectName = project.name.startsWith('#$') ?
                    this.localizationService.getString(`Agito.Hilti.Profis3.Documents.SpecialProject.${project.name.substring(2)}`) :
                    project.name;
                const folderName = path ? `${path}/${projectName}` : `${projectName}`;
                for (const design of project.DesignList) {
                    const fileContent = convertedDesigns[design.documentid];
                    const fileName = `${folderName}/${design.documentname}.pe`
                    zip.file(fileName, fileContent);
                }
                if (project.SubProjectList != null) {
                    addProjectsToZip(project.SubProjectList, folderName);
                }
            }
        };

        addProjectsToZip(projects);
        const blob = await zip.generateAsync({ type: 'blob' });
        return blob;
    }
}
