import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringNullOrEmpty } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';

import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { LocalizationService } from './localization.service';
import { UserSettingsService } from './user-settings.service';

// Hilti Applications
export interface ProductInfoHiltiApplicationEntity {
    Id: number;
    TrackingId: string;
    DisplayKey: string;
    SubtitleKey: string;
    SortOrder: number;
    Image: string;
    ShowNewLabel: boolean;

    PerHubAccess: boolean;
    Links: ProductInfoHiltiApplicationLinksEntity[];
}

export interface ProductInfoHiltiApplicationLinksEntity {
    RegionId: number;
    Link: string;
    LcId: number;
}


// Quick start external apps
export interface ProductInfoExternalApplicationEntity {
    Id: number;
    TrackingId: string;
    Name: string;
    TranslationKey: string;
    Image: string;
    ShowNewLabel: boolean;

    Links: ProductInfoExternalAppLinksEntity[]
}

export interface ProductInfoExternalAppLinksEntity {
    RegionId: number;
    Link: string;
}


// Service
@Injectable({
    providedIn: 'root'
})
export class ExternalAppsService {
    // Data
    private hiltiApplicationsList: ProductInfoHiltiApplicationEntity[] = [];
    private externalAppsList: ProductInfoExternalApplicationEntity[] = [];

    // Cache
    private cachedHiltiApplicationsRegionId = -1;
    private cachedHiltiApplications: ProductInfoHiltiApplicationEntity[] = null;
    private cachedExternalAppsRegionId = -1;
    private cachedExternalApps: ProductInfoExternalApplicationEntity[] = null;


    constructor(
        private apiService: ApiService,
        private localizationService: LocalizationService,
        private userSettingsService: UserSettingsService
    ) {
    }


    // Properties
    // Public
    // Hilti Applications
    public get hiltiApplications() {
        if (
            this.cachedHiltiApplicationsRegionId != this.regionId
            ||
            this.cachedHiltiApplications == null
        ) {
            // Reload cache
            this.cachedHiltiApplications = this.hiltiApplicationsList.filter(x => this.isHiltiApplicationVisible(x.Id));
            this.cachedHiltiApplicationsRegionId = this.regionId;
        }

        return this.cachedHiltiApplications;
    }

    // Quick start external apps
    public get externalApps() {
        if (
            this.cachedExternalAppsRegionId != this.regionId
            ||
            this.cachedExternalApps == null
        ) {
            // Reload cache
            this.cachedExternalApps = this.externalAppsList.filter(x => this.isExternalAppVisible(x.Id));
            this.cachedExternalAppsRegionId = this.regionId;
        }

        return this.cachedExternalApps;
    }

    // Private
    // Common
    private get regionId() {
        return this.userSettingsService.settings.application.general.regionId.value;
    }

    private get lcId() {
        return this.userSettingsService.settings.application.general.languageId.value;
    }

    // Methods
    // Public
    // Hilti Applications
    public async getHiltiApplications(apiOptions?: ApiOptions): Promise<ProductInfoHiltiApplicationEntity[]> {
        const url = `${environment.productInformationServiceUrl}HiltiApplications/GetHiltiApplications`;
        return (await this.apiService.request<ProductInfoHiltiApplicationEntity[]>(new HttpRequest('GET', url), apiOptions)).body;
    }

    public initializeHiltiApplications(hiltiApplications: ProductInfoHiltiApplicationEntity[]) {
        this.hiltiApplicationsList = hiltiApplications ?? [];
    }

    public getHiltiApplication(id: number) {
        return this.hiltiApplicationsList.find(x => x.Id == id);
    }

    public getHiltiApplicationLink(hiltiApplication: ProductInfoHiltiApplicationEntity) {
        if (hiltiApplication == null) {
            return undefined;
        }

        let regionId = 0;
        let lcId = 0;
        if (hiltiApplication.PerHubAccess) {
            // Enabled on per-hub basis; check if link for currently-selected region is maintained.
            regionId = this.regionId;
            lcId = this.lcId;
        }
        else {
            // Enabled on per-user basis
            // Check entry for region 0
        }

        const links = hiltiApplication.Links?.filter(x => x.RegionId == regionId && !stringNullOrEmpty(x.Link));
        const link = links?.find(x => x.LcId == lcId) ?? links?.find(x => x.LcId == 0);
        return link?.Link;
    }

    public getHiltiApplicationTitle(app: ProductInfoHiltiApplicationEntity) {
        const key = `Agito.Hilti.Profis3.ProjectAndDesign.HiltiAplications.${app.DisplayKey}`;
        if (this.localizationService.getKeyExists(key)) {
            return this.localizationService.getString(key);
        }

        return app.DisplayKey;
    }

    public getHiltiApplicationSubtitle(app: ProductInfoHiltiApplicationEntity) {
        if (stringNullOrEmpty(app.SubtitleKey)) {
            return '';
        }

        const key = `Agito.Hilti.Profis3.ProjectAndDesign.HiltiAplications.${app.SubtitleKey}`;
        if (this.localizationService.getKeyExists(key)) {
            return this.localizationService.getString(key);
        }

        return '';
    }

    // Quick start external apps
    public async getExternalAppsFromService(apiOptions?: ApiOptions): Promise<ProductInfoExternalApplicationEntity[]> {
        const url = `${environment.productInformationServiceUrl}QuickstartExternalApps/GetQuickstartExternalApps`;
        return (await this.apiService.request<ProductInfoExternalApplicationEntity[]>(new HttpRequest('GET', url), apiOptions)).body;
    }

    public initializeExternalApps(externalApps: ProductInfoExternalApplicationEntity[]) {
        this.externalAppsList = externalApps ?? [];
    }

    public getExternalApp(id: number) {
        return this.externalAppsList.find(x => x.Id == id);
    }

    public getExternalAppLink(externalApp: ProductInfoExternalApplicationEntity) {
        if (externalApp == null) {
            return undefined;
        }

        // Enabled on per-hub basis; check if link for currently-selected region is maintained.
        const regionId = this.regionId;
        const link = externalApp.Links?.find(x => x.RegionId == regionId && !stringNullOrEmpty(x.Link));
        return link?.Link;
    }

    public getExternalAppTitle(app: ProductInfoExternalApplicationEntity, keyPrefix = 'Agito.Hilti.Profis3.ProjectAndDesign.Main.QuickStart.ExternalApplications') {
        const key = `${keyPrefix}.${app.TranslationKey}`;
        if (this.localizationService.getKeyExists(key)) {
            return this.localizationService.getString(key);
        }

        return app.TranslationKey;
    }


    // Private
    // Hilti Applications
    private isHiltiApplicationVisible(id: number): boolean {
        const url = this.getHiltiApplicationLink(this.getHiltiApplication(id)) ?? '';
        return !stringNullOrEmpty(url);
    }

    // Quick start external apps
    private isExternalAppVisible(id: number): boolean {
        const url = this.getExternalAppLink(this.getExternalApp(id)) ?? '';
        return !stringNullOrEmpty(url);
    }
}
