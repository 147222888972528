import { Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Component, } from '@angular/core';
import BotAiImage from '../../../images/bot-ai.png';
import { AppStorageService } from '../../services/app-storage.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';

const chatbotMessages = 'chatbotMessages';

interface QuestionAnwser {
    id: string;
    question: string;
    answer?: string;
    waiting?: boolean;
    showDislikeInput?: boolean;
    dislikeComment?: Text;
}

@Component({
    selector: 'app-smartin-chatbot',
    templateUrl: './smartin-chatbot.component.html',
    styleUrls: ['./smartin-chatbot.component.scss']
})
export class SmartinChatbotComponent implements OnInit {

    @Input()
    public isHomePage: boolean;

    @Input()
    public designType: number;

    @Input()
    public regionId: number;

    @ViewChildren('messages') messages: QueryList<unknown>;

    public openWindow = false;
    public waitingForResponse = false;
    public newQuestion = '';
    public questionAnwsers: QuestionAnwser[] = [];

    constructor(
        private featureVisibilityService: FeatureVisibilityService,
        private readonly appStorageService: AppStorageService) {
    }

    ngOnInit(): void {
        this.questionAnwsers = this.appStorageService.get<QuestionAnwser[]>(chatbotMessages) ?? []
    }

    public get showEmpyState() { return this.questionAnwsers.length == 0; }
    public get smartinImage() { return BotAiImage; }

    public close() {
        this.openWindow = false;
    }

    public open() {
        this.openWindow = true;
    }
    public clear() {
        this.questionAnwsers = [];
        this.saveQuestionsToStorage();
    }

    public get isEnabled() {
        const isAllowedForRegion = this.featureVisibilityService.getFeatureValue('PE_Smartin_Chat_Regions', []).includes(this.regionId);
        if (!isAllowedForRegion) {
            return false;
        }

        const isAllowedForDesignType = this.featureVisibilityService.getFeatureValue('PE_Smartin_Chat_Modules', []).includes(this.designType);
        return this.isHomePage || isAllowedForDesignType;
    }

    public likeAnswer(qa: QuestionAnwser) {
        // todo add code to like the answer
    }

    public dislikeAnswer(qa: QuestionAnwser) {
        this.questionAnwsers[this.questionAnwsers.indexOf(qa)].showDislikeInput = true;
        this.saveQuestionsToStorage();
    }
    public submitDislike(qa: QuestionAnwser) {
        qa.showDislikeInput = false;
        this.questionAnwsers[this.questionAnwsers.indexOf(qa)] = qa;
        this.saveQuestionsToStorage();
    }

    public send() {
        if (this.newQuestion.trim() === '' || this.waitingForResponse) {
            return;
        }

        this.waitingForResponse = true;
        const questionAnwser = { question: this.newQuestion, answer: '' } as QuestionAnwser;
        this.questionAnwsers.push(questionAnwser);
        this.newQuestion = '';

        // Ensure only the last 20 items are stored
        if (this.questionAnwsers.length > 20) {
            this.questionAnwsers.shift();
        }

        this.saveQuestionsToStorage();
        this.getAnswer(questionAnwser.question)
    }

    public getAnswer(question: string) {
        setTimeout(() => {
            this.waitingForResponse = false;
            const lastQuestionAnwser = this.questionAnwsers[this.questionAnwsers.length - 1];
            lastQuestionAnwser.answer = 'I am not sure how to answer that question.';
            this.saveQuestionsToStorage();
        }, 3000);
    }

    private saveQuestionsToStorage(): void {
        this.appStorageService.set(chatbotMessages, this.questionAnwsers);
    }
}
