import { HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    RegionHub
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    PeInitialDataModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Web.Models';
import {
    UserLicense
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Licensing.Entities';
import { ensureLanguage } from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { ApiOptions } from '@profis-engineering/pe-ui-common/services/api.common';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';

import { environment } from '../../environments/environment';
import { FeatureFlag } from '../entities/feature-flags';
import { ApiService } from './api.service';
import { ApplicationVersionsService } from './application-versions.service';
import { CommonCodeListService } from './common-code-list.service';
import { CommonTrackingService } from './common-tracking.service';
import { DesignTemplateService } from './design-template.service';
import { DocumentService } from './document.service';
import { ExternalAppsService } from './external-apps.service';
import { FavoritesService } from './favorites.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { FeaturesVisibilityInfoService } from './features-visibility-info.service';
import { GeneralErrorService } from './general-error.service';
import { IntegrationsDocumentService } from './integrations-document.service';
import { LaunchDarklyService } from './launch-darkly.service';
import { LicenseService } from './license.service';
import { LocalizationService } from './localization.service';
import { LongRegistrationService } from './long-registration.service';
import { ModalService } from './modal.service';
import { ModulesService } from './modules.service';
import { OfflineService } from './offline.service';
import { ProductInformationService } from './product-information.service';
import { ReportTemplateService } from './report-template.service';
import { ReportService } from './report.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';
import { RemoteLoggingService } from './remote-logging.service';

export enum LoadDataRejectType {
    Unknown,
    ContinueLoading,
}

export interface LoadDataRejectResponse {
    type: LoadDataRejectType;
    message: any;
}

@Injectable({
    providedIn: 'root'
})
export class DataService {
    public initialDataNotNeeded = false;    // Do not load initial data as it is not needed (e.g. when displaying /version)
    public initialDataLoaded = false;
    private pendingLoadInitialData: Promise<void>;

    constructor(
        private readonly offlineService: OfflineService,
        private readonly userSettingsService: UserSettingsService,
        private readonly localizationService: LocalizationService,
        private readonly favoritesService: FavoritesService,
        private readonly userService: UserService,
        private readonly apiService: ApiService,
        private readonly modalService: ModalService,
        private readonly licenseService: LicenseService,
        private readonly productInformationService: ProductInformationService,
        private readonly reportService: ReportService,
        private readonly generalErrorService: GeneralErrorService,
        private readonly designTemplateService: DesignTemplateService,
        private readonly documentService: DocumentService,
        private readonly featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private readonly integrationsDocumentService: IntegrationsDocumentService,
        private readonly reportTemplateService: ReportTemplateService,
        private readonly commonCodeListService: CommonCodeListService,
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly longRegistrationService: LongRegistrationService,
        private readonly launchDarklyService: LaunchDarklyService,
        private readonly modulesService: ModulesService,
        private readonly externalAppsService: ExternalAppsService,
        private readonly commonTrackingService: CommonTrackingService,
        private readonly applicationVersionsService: ApplicationVersionsService,
        private readonly remoteLoggingService: RemoteLoggingService
    ) {
    }

    public async loadInitialData(): Promise<void> {
        if (!this.userService.isAuthenticated) {
            throw new Error('Unauthenticated');
        }

        if (this.initialDataNotNeeded) {
            return;
        }

        if (this.pendingLoadInitialData == null) {
            this.pendingLoadInitialData = this.loadInitialDataInternal();
        }
        await this.pendingLoadInitialData;
    }

    public async setApplicationVersion() {
        await this.applicationVersionsService.setApplicationVersion();
    }

    /**
     * Loads the region and language (that the user has selected during setup) from registry.
     * @param noSave: If false, the region and language are saved to the user's application settings, along with default favorite inputs.
     */
    private async loadRegionFromRegistry() {
        const regions = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        let region: CommonRegion = null;

        const languages = this.commonCodeListService.commonCodeLists[CommonCodeList.Language] as Language[];
        let language: Language = null;

        const setupDataReg = await this.offlineService.getSetupLanguageFromRegistry();
        if (setupDataReg != null) {
            const setupData = this.offlineService.parseSetupLanguage(setupDataReg);

            // Find region
            const countryCode = setupData.countryCode.toLowerCase();
            region = regions.find(region =>
                region.countryCode != null &&
                region.countryCode.toLowerCase() == countryCode);

            const culture = setupData.language.toLocaleLowerCase();
            language = languages.find(language =>
                language.culture != null &&
                language.culture.toLowerCase() == culture);

            if (language == null) {
                const split = culture.split('-');
                if (split.length > 1) {
                    language = languages.find(language =>
                        language.culture != null &&
                        language.culture.toLowerCase() == split[0]);
                }
            }
        }

        if (region != null && language != null) {
            await this.userSettingsService.setFirstTimeRegionLanguage(region, language);
        }
        else {
            throw new Error('region or language not found');
        }
    }

    private async getInitialDataCommonInternal(options?: ApiOptions) {
        this.registerFeaturesVisibilityServiceDefaultFlags();

        let url = `${environment.peCommonServiceUrl}InitialData/GetPeInitialData`;
        const translate = environment.translate || false;
        if (translate) {
            url = `${url}?pseudoTranslate=${translate}`;
        }

        const { body: dataEntity } = await this.apiService.request<PeInitialDataModel>(new HttpRequest('GET', url), options);

        // init codelists
        this.commonCodeListService.initialize(dataEntity.CodeLists);

        // init design templates
        this.designTemplateService.initialize(dataEntity.DesignTemplateLists || []);

        // init design templates for new home page
        if (dataEntity.DesignTemplates) {
            this.designTemplateService.initializeAll(
                dataEntity.DesignTemplates, dataEntity.ProjectTemplateArchive || [], dataEntity.DesignTemplateArchive || []);
        }

        // wait for all modules to load
        await this.modulesService.modulesLoadedPromise;

        // init user settings
        const ccmsUserSettings = {
            Address: dataEntity.CcmsUserData?.Address,
            CompanyName: dataEntity.CcmsUserData?.CompanyName,
            EmailAddress: dataEntity.CcmsUserData?.EmailAddress,
            Fax: dataEntity.CcmsUserData?.Fax,
            FullName: dataEntity.CcmsUserData?.FullName,
            Phone: dataEntity.CcmsUserData?.Phone
        }
        this.userSettingsService.initialize(dataEntity.UserSettings || [], ccmsUserSettings);
        await this.localizationService.getTranslations(this.userSettingsService.getLanguage().culture);

        // application version
        await this.setApplicationVersion();

        // init report templates
        this.reportService.initialize(dataEntity.ReportTemplates || []);

        // init document service results
        this.documentService.initialize(
            dataEntity.Documents || { projects: {}, documents: {} },
            dataEntity.ProjectArchive || [],
            dataEntity.DocumentArchive || []);

        // init featuresVisibilityInfo
        this.featuresVisibilityInfoService.initialize(dataEntity.FeaturesVisibilityInfo || []);

        // initialize integration services
        this.integrationsDocumentService.initialize(dataEntity.IntegrationDocuments);
    }

    private async syncTrackingService() {
        const url = `${environment.baseplateApplicationWebServiceUrl}SyncTracking`;

        await this.apiService.request<boolean>(new HttpRequest('GET', url), { supressErrorMessage: true });
    }

    private async loadInitialDataInternal(): Promise<void> {
        let pageReload = false;
        const pageReloadListener = () => {
            pageReload = true;
        };
        window.addEventListener('beforeunload', pageReloadListener, false);

        try {
            // load feature flags first
            if (!this.launchDarklyService.isInitialized) {
                await this.launchDarklyService.initialize(this.userService.authentication.userId, this.userService.authentication.userName, this.userService.authentication.country);
            }

            // check for maintenance page
            if (this.featureVisibilityService.isFeatureEnabled('MaintenancePage')) {
                // root component will show maintenance page when featureFlagsService.maintenancePage is true
                return new Promise(() => {
                    // returns a promise that will never end
                });
            }

            this.remoteLoggingService.init(this.userService.authentication.userId, this.userService.authentication.userName);

            await this.getInitialDataCommonInternal({ supressErrorMessage: true, logCallToRemoteLogging: true });

            await this.userService.setAuthenticated(this.userService.authentication, this.offlineService.isOffline); // save to session

            const usersLanguage = this.userSettingsService.settings.application.general.languageId.value == null ?
                '' :
                this.userSettingsService.getLanguage().culture;

            await this.localizationService.getTranslations(
                ensureLanguage(usersLanguage, this.userSettingsService),
                { supressErrorMessage: true });

            await this.handleLongRegistration();

            let url = `${environment.peCommonServiceUrl}License/GetLicense`;
            const forceFreeLicense = this.userSettingsService.settings.application.general.forceFreeLicense.value;

            if (forceFreeLicense) {
                url = `${url}?forceFreeLicense=${forceFreeLicense}`;
            }

            const request = new HttpRequest('GET', url, {
                responseType: 'json'
            });

            const res = await this.apiService.request<UserLicense>(request, { supressErrorMessage: true, logCallToRemoteLogging: true });
            const userLicense = res.body;
            const rawLicense = this.userService.authentication.subscription_info.AuthorizationEntryList[0].Licenses;

            this.licenseService.initialize(userLicense, rawLicense);
            this.checkIfLicenseParsingFailed();

            if (this.userSettingsService.settings.application.general.regionId.value == null) {
                if (this.offlineService.isOffline) {
                    await this.loadRegionFromRegistry();
                }
                else {
                    await this.modalService.openSelectRegionLanguage().closed;
                }
                // we have to reload report templates after default one is created
                const templates = await this.reportTemplateService.getTemplates({ supressErrorMessage: true });
                this.reportService.initialize(templates);
            }

            await this.loadProductInformationServiceData();

            // Check if it's new user, before changes are made to settings
            await this.checkIfNewUser();

            if (this.offlineService.isOffline) {
                await this.offlineService.checkForUpdates();
                await this.syncTrackingService();
                await this.offlineService.loading(true);
            }

            await this.favoritesService.initialize();

            await this.modulesService.initialDataLoaded();
            this.initialDataLoaded = true;

            // for trial user or trial expired users we have to display trial banner
            if (this.licenseService.isTrial() || this.licenseService.isTrialExpired() ||
                this.licenseService.isNoLicenseHandledAsFree() && !this.userSettingsService.settings.stayOnStandardLicense.value && !environment.handleNoLicenseAsFreeCountryCodesNoPopUp.includes(this.userService.authentication.country)) {
                this.licenseService.displayTrialInfo = true;
            }

            // for floating seat being taken, we have to display a pop-up
            if (this.licenseService.floatingLimitReached) {
                this.licenseService.displayFloatingLimitReachedInfo = true;
            }
        }
        catch (error) {
            // Handle LoadDataRejectResponse (if interface provided).
            this.handleInitialDataError(error, pageReload);

            throw error;
        }
        finally {
            window.removeEventListener(
                'beforeunload',
                pageReloadListener,
                false);
        }
    }

    private async checkIfNewUser() {
        // New user tracking should be the first thing to check. Only track if not already marked as tracked.
        // Marked as tracked are following users:
        //  - old existing users (registered before feature was release, not actually tracked, just marked as tracked)
        //  - new users (registered after feature was released) that were already tracked.
        if (!this.userSettingsService.settings.firstLoginTracked.value) {
            // Mark as tracked even if the feature is disabled. To avoid tracking existing users if enabled later.
            this.userSettingsService.settings.firstLoginTracked.value = true;
            await this.userSettingsService.save();
            // Do actual tracking only if the feature is enabled.
            if (environment.trackFirstLoginEnabled) {
                await this.commonTrackingService.trackUserFirstLogin();
            }
        }

        if (!this.offlineService.isOffline && this.userSettingsService.settings.application.general.userAgreement.value == null) {
            // open the modal for user agreement
            await this.modalService.openUserAgreement().closed;
        }

        if (this.userSettingsService.settings.application.general.userPrivacy.value == null) {
            const region = this.userSettingsService.getCommonRegionById(
                this.userSettingsService.settings.application.general.regionId.value);

            // User agreement privacy not shown for offline
            if (region != null && region.hubId == RegionHub.W1_HNA) {
                this.userSettingsService.settings.application.general.userPrivacy.value = true;
            }
            else {
                // open the modal for user privacy
                await this.modalService.openUserAgreementPrivacy().closed;
            }
        }

        if (this.userSettingsService.settings.application.general.isNewUser.value) {
            await this.productInformationService.openNewUserCampaigns();
        }
    }

    private handleInitialDataError(error: any, pageReload: boolean) {
        const rejectResponse = error as LoadDataRejectResponse;
        if (rejectResponse != null) {
            if (rejectResponse.type == LoadDataRejectType.ContinueLoading) {
                this.generalErrorService.showErrorGeneral = false;
                throw error;
            }
        }

        // Handle any response
        if (error instanceof HttpResponseBase &&
            error.status != null &&
            error.status == 401 ||
            pageReload) {
            this.generalErrorService.showErrorGeneral = false;
        }
        else {
            this.pendingLoadInitialData = null;
            this.generalErrorService.showErrorGeneral = true;
        }
    }

    private async loadProductInformationServiceData() {
        if (this.productInformationService.isProductInformationServiceEnabled) {
            const culture = this.localizationService.selectedLanguage;

            const releaseNotesPromise = (async () => {
                const releaseNotes = await this.productInformationService.getLatestReleaseNoteFromService(culture, { supressErrorMessage: true });
                this.productInformationService.initializeReleaseNotes(releaseNotes);
            })();

            const campaignsPromise = (async () => {
                const campaigns = await this.productInformationService.getMarketingCampaignsFromService({ supressErrorMessage: true });
                this.productInformationService.initializeMarketingCampaigns(campaigns);
            })();

            const regionLinksPromise = (async () => {
                const regionLinks = await this.productInformationService.getRegionLinksFromService({ supressErrorMessage: true });
                this.productInformationService.initializeRegionLinks(regionLinks);
            })();

            const hiltiApplicationsPromise = (async () => {
                const hiltiApplications = await this.externalAppsService.getHiltiApplications(({ supressErrorMessage: true }));
                this.externalAppsService.initializeHiltiApplications(hiltiApplications);
            })();

            const designGuidesPromise = (async () => {
                const designGuides = await this.productInformationService.getDesignGuidesCodeLists(({ supressErrorMessage: true }));
                this.productInformationService.initializeDesignGuideList(designGuides);
            })();

            const woodLinksPromise = (async () => {
                const woodLinks = await this.productInformationService.getWoodLinksCodeLists(({ supressErrorMessage: true }));
                this.productInformationService.initializeWoodLinksList(woodLinks);
            })();

            const externalAppsPromise = (async () => {
                const externalApps = await this.externalAppsService.getExternalAppsFromService(({ supressErrorMessage: true }));
                this.externalAppsService.initializeExternalApps(externalApps);
            })();

            const infoLinksPromise = (async () => {
                const infoLinks = await this.productInformationService.getInfoLinksFromService(({ supressErrorMessage: true }));
                this.productInformationService.initializeInfoLinks(infoLinks);
            })();

            await Promise.all([releaseNotesPromise, campaignsPromise, regionLinksPromise, hiltiApplicationsPromise, designGuidesPromise, woodLinksPromise, externalAppsPromise, infoLinksPromise]);
        }
    }

    private checkIfLicenseParsingFailed() {
        if (this.licenseService.isLicenseParsingFailed()) {
            this.modalService.openAlertLicenseError();
        }
    }

    private async handleLongRegistration() {
        // if user is online && customerOriginId == null && country supports long registration.
        if (this.longRegistrationService.isLongRegistrationNeeded()) {
            await this.longRegistrationService.redirectToLongRegistration();
        }
    }

    private registerFeaturesVisibilityServiceDefaultFlags() {
        if (environment.useDevFeatures) {
            // Register DevFeatures
            this.featureVisibilityService.registerFeature('BulkReport', true);
        }

        if (environment.featureFlagOverrides != null) {
            // Register Feature Flags overrides
            const allFlags = Object.getOwnPropertyNames(environment.featureFlagOverrides) as FeatureFlag[];

            for (const flag of allFlags) {
                this.featureVisibilityService.registerFeature(flag, environment.featureFlagOverrides[flag]);
            }
        }
    }
}
