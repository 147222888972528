import { ComponentFactoryResolver } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Design } from '../../entities/design';
import { FeaturesHelper } from '../../helpers/bw-compat/features-helper';
import { openConfirmChangeForOffline } from '../../helpers/offline-helper';
import { ApiService } from '../../services/api.service';
import { BrowserService } from '../../services/browser.service';
import { publish } from '../../services/calculation/calculation-service-base';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentService } from '../../services/document.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { ModulesService } from '../../services/modules.service';
import { OfflineService } from '../../services/offline.service';
import { RoutingService } from '../../services/routing.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { SmartinChatbotComponent } from '../smartin-chatbot/smartin-chatbot.component';

interface IModuleIMainComponentInternal extends IMainComponent, HTMLElement { }

interface IMainComponent {
    glDebug: boolean;

    openConfirmChangeForOffline?: () => void;
    publish?: () => Promise<void>;
    processDesignClose?: () => Promise<void>;
    processDesignBrowserUnload?: () => Promise<void>;
}

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

    public design: Design;

    constructor(
        private routingService: RoutingService,
        private activatedRoute: ActivatedRoute,
        private localizationService: LocalizationService,
        private offlineService: OfflineService,
        private modalService: ModalService,
        private userService: UserService,
        private designTemplateService: DesignTemplateService,
        private documentService: DocumentService,
        private apiService: ApiService,
        private browser: BrowserService,
        private modulesService: ModulesService,
        private elementRef: ElementRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private userSettingsService: UserSettingsService
    ) { }

    public ngOnInit(): void {
        this.design = this.userService.design;

        // add body class
        document.body.classList.add('main-view-body');

        // init main component for opened design
        this.initMainComponentModule();
    }

    public ngOnDestroy(): void {
        if (this.design != null) {
            // angular bug: template render is still called multiple times after ngOnDestroy for some reason
            // this causes errors since this.design is already null
            setTimeout(() => {
                this.design = null;
            }, 100);
        }

        // remove body class
        document.body.classList.remove('main-view-body');
    }

    public get glDebug() {
        return this.activatedRoute.snapshot.queryParamMap.has('debug');
    }

    public get isAsadEnabled() {
        return this.userService.isAsadEnabled;
    }

    public get isAsadDebugEnabled() {
        return this.userService.isAsadDebugEnabled;
    }

    private get designInfo() {
        return this.modulesService.getDesignInfoForDesignType(this.design.designType.id, this.design.region.id, this.design.connectionType);
    }

    public openConfirmChangeForOffline() {
        openConfirmChangeForOffline(
            this.routingService,
            this.offlineService,
            this.modalService,
            this.localizationService,
            this.documentService,
            this.apiService,
            this.browser,
            this.design,
            true,
            window);
    }

    public publish() {
        return publish(this.documentService, this.designTemplateService, this.design);
    }

    public async processDesignClose() {
        return this.design.processDesignClose(true);
    }

    public processDesignBrowserUnload() {
        return this.design.processDesignBrowserUnload(this.documentService.getSessionKeyForDesign(this.design.id));
    }

    private initMainComponentModule() {
        // create component
        const component = window.document.createElement(this.designInfo.mainTagName) as IModuleIMainComponentInternal;

        // set component inputs
        component.glDebug = this.glDebug;

        component.openConfirmChangeForOffline = this.openConfirmChangeForOffline.bind(this);
        component.publish = this.publish.bind(this);
        component.processDesignClose = this.processDesignClose.bind(this);
        component.processDesignBrowserUnload = this.processDesignBrowserUnload.bind(this);

        // attach component to the document
        this.elementRef.nativeElement.replaceChildren(component);

        this.loadSmartinChatbotComponent();
    }

    private loadSmartinChatbotComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SmartinChatbotComponent);
        const componentRef = this.viewContainerRef.createComponent(componentFactory);

        // Set inputs on the component instance
        componentRef.instance.designType = this.designInfo.designTypeId;
        componentRef.instance.regionId = this.regionId;


        this.elementRef.nativeElement.appendChild(componentRef.location.nativeElement);
    }

    public get regionId() {
        return this.userSettingsService.settings.application.general.regionId.value;
    }
}
