import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import {
    DesignType as DesignTypeCommon
} from '@profis-engineering/pe-ui-common/entities/code-lists/design-type';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import {
    AppSettings, IAbpInfo, IDesignInfo, IDesignListInfo, IDesignManagement, IFavoritesInfo, IImportDesignProvider,
    IIntegrationsInfo, IQuickStartApplication, IServiceNameMapping, ITrackingInfo, IUserSettingsInfo, IVirtualTour
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { IModuleVersionInfoProvider } from '@profis-engineering/pe-ui-common/entities/service-version';
import {
    DocumentIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { isValidUrl } from '@profis-engineering/pe-ui-common/helpers/url-helper';

declare global {
    interface Window {
        modules: Module[];
    }
}

interface Module {
    name: string;
    bootstrap: () => void;
    isLoaded: boolean;
}

export const enum DesignTypeId {
    Unknown = 0,
    // MODULARIZATION: can be removed once modularization is done.
    Concrete = 1,
    Handrail = 2,
    Masonry = 3,
    MetalDeck = 4,
    Concrete2Concrete = 105,
    DiaphragmDesign = 106,
    CurtainWall = 107
}

/**
 * Service used to consolidate data obtained from various modules (e.g. pe-ui-pe, pe-ui-c2c).
 */
@Injectable({
    providedIn: 'root'
})
export class ModulesService {
    private quickStartModulesAddedSubject = new Subject<void>();
    public quickStartModulesAdded = this.quickStartModulesAddedSubject.asObservable();

    private designListInfoProvidedSubject = new Subject<void>();
    public designListInfoProvided = this.designListInfoProvidedSubject.asObservable();

    private designInfoProvidedSubject = new Subject<void>();
    public designInfoProvided = this.designInfoProvidedSubject.asObservable();

    public modulesLoaded = false;
    public modulesLoadedPromise: Promise<void>;

    private modulesLoadedPromiseResolve: () => void;

    private authenticationRequiredServices: string[] = [];
    private serviceNameMapping: IServiceNameMapping = {};
    private appSettingsData: AppSettings[] = [];
    private quickStartApplications: IQuickStartApplication[] = [];
    private virtualTours: IVirtualTour[] = [];
    private designInfo: IDesignInfo[] = [];
    private designListInfo: IDesignListInfo[] = [];
    private importDesignProviders: IImportDesignProvider[] = [];
    private importFileExtensions: string[] = [];
    private abpData: IAbpInfo[] = [];
    private integrationsData: IIntegrationsInfo[] = [];
    private designTypesInternal: DesignTypeCommon[] = [];
    private favoritesInfo: IFavoritesInfo[] = [];
    private userSettingsInfo: IUserSettingsInfo[] = [];
    private trackingInfo: ITrackingInfo[] = [];
    private designManagementInfo: IDesignManagement[] = [];
    private moduleVersionInfoProviders: IModuleVersionInfoProvider[] = [];
    private initialDataLoadedEvents: (() => Promise<void> | void)[] = [];
    private userAuthenticatedEvents: (() => Promise<void> | void)[] = [];
    private tokenRefreshedEvents: (() => Promise<void> | void)[] = [];

    constructor() {
        this.modulesLoadedPromise = new Promise<void>(resolve => this.modulesLoadedPromiseResolve = resolve);
    }

    public get designTypes() {
        return this.designTypesInternal;
    }

    public bootstrapModules() {
        document.addEventListener('DOMContentLoaded', () => {
            for (const module of window.modules || []) {
                module.bootstrap();
            }

            // if after 5 seconds the modules are still not loaded just continue the page load
            setTimeout(() => {
                this.markModulesLoaded();

                // log not loaded modules
                // TODO TEAM: fix pe-ui-common and pe-ui-decking which for some reason are loaded differently
                const notLoadedModules = window.modules.filter(x => x.name != "pe-ui-common" && x.name != 'pe-ui-decking' && !x.isLoaded);
                if (notLoadedModules.length > 0) {
                    console.warn('not all modules were loaded after 5 seconds of wait time: %O', notLoadedModules)
                }
            }, 5000);
        });
    }

    public markModulesLoaded() {
        if (!this.modulesLoaded) {
            this.modulesLoaded = true;
            this.modulesLoadedPromiseResolve();
        }
    }

    public async downloadDesign(name: string, designId: string, designTypeId: number) {
        this.ensureModulesLoaded();

        const designInfo = this.designListInfo.find(x => x.designTypeId == designTypeId && x.downloadDesign != null);
        if (designInfo != null) {
            await SafeFunctionInvokerHelper.safeInvoke(() => designInfo.downloadDesign(name, designId));
        }
    }

    // Authentication required services
    public addAuthenticationRequiredServices(services: string[]) {
        if (services.length) {
            this.authenticationRequiredServices = this.authenticationRequiredServices.concat(services);
        }
    }

    public serviceRequiresAuthentication(url: string) {
        // if it's a module url then the module is already loaded
        // no need for this.ensureModulesLoaded();

        return this.authenticationRequiredServices.some((prefix) => isValidUrl(prefix) && url.startsWith(prefix));
    }


    // Service name mappings
    public addServiceNameMappings(mapping: IServiceNameMapping) {
        if (!mapping) {
            return;
        }

        for (const key in mapping) {
            this.serviceNameMapping[key] = mapping[key];
        }
    }

    public getServiceName(url: string): string {
        // if it's a module url then the module is already loaded
        // no need for this.ensureModulesLoaded();

        for (const mappingUrl in this.serviceNameMapping) {
            if (url.startsWith(mappingUrl)) {
                return this.serviceNameMapping[mappingUrl];
            }
        }

        return undefined;
    }


    public addAppSettingsData(appSettings: AppSettings[]) {
        if (appSettings.length) {
            this.appSettingsData = this.appSettingsData.concat(appSettings);
        }
    }

    public updateAppSettingsData(appSettings: AppSettings[]) {
        if (!appSettings.length)
            return;

        // Filter out appSettingsData with the same name and add the new ones (they are being updated)
        this.appSettingsData = this.appSettingsData.filter(appSetting => appSettings.every(x => x.name !== appSetting.name));
        this.addAppSettingsData(appSettings);
    }

    public getAppSettingsData(): AppSettings[] {
        this.ensureModulesLoaded();

        return this.appSettingsData;
    }


    public addQuickStartApplications(quickStartApplications: IQuickStartApplication[]) {
        if (quickStartApplications.length) {
            this.quickStartApplications = this.quickStartApplications.concat(quickStartApplications);

            this.quickStartModulesAddedSubject.next();
        }
    }

    public updateQuickStartApplications(quickStartApplications: IQuickStartApplication[]) {
        if (!quickStartApplications?.length)
            return;

        // Filter out quickStartApplications with the same designType and add the new ones (they are being updated)
        this.quickStartApplications = this.quickStartApplications.filter(quickStartApplication => quickStartApplications.every(x => x.designType !== quickStartApplication.designType));
        this.addQuickStartApplications(quickStartApplications);
    }

    public getQuickStartApplications(): IQuickStartApplication[] {
        this.ensureModulesLoaded();

        return this.quickStartApplications;
    }


    public addVirtualTours(virtualTours: IVirtualTour[]) {
        if (virtualTours.length) {
            this.virtualTours = this.virtualTours.concat(virtualTours);
        }
    }

    public getVirtualTours(): IVirtualTour[] {
        this.ensureModulesLoaded();

        return this.virtualTours;
    }


    public addDesignInfo(designInfo: IDesignInfo[]) {
        if (designInfo.length) {
            this.designInfo = this.designInfo.concat(designInfo);

            this.designInfoProvidedSubject.next();
        }
    }

    public updateDesignInfo(designInfos: IDesignInfo[]) {
        if (!designInfos?.length)
            return;

        // Filter out designInfo with the same designTypeId and add the new ones (they are being updated)
        this.designInfo = this.designInfo.filter(design => designInfos.every(x => x.designTypeId !== design.designTypeId));
        this.addDesignInfo(designInfos);
    }

    public getDesignInfo(): IDesignInfo[] {
        this.ensureModulesLoaded();

        return this.designInfo;
    }

    // this code should moved to specific module after modularization
    public getDesignInfoForDesignType(designTypeId: number, regionId: number, connectionType?: number) {
        this.ensureModulesLoaded();

        return this.getDesignInfo().find(x => x.designTypeId == designTypeId
            && (!('connectionType' in x) || (x.connectionType as Array<number>).includes(connectionType) || connectionType == null)
            && SafeFunctionInvokerHelper.safeInvoke(() => x.isAvailable(regionId), false));
    }


    public addDesignListInfo(designListInfo: IDesignListInfo[]) {
        if (designListInfo.length) {
            this.designListInfo = this.designListInfo.concat(designListInfo);

            this.designListInfoProvidedSubject.next();
        }
    }

    public updateDesignListInfo(designListInfos: IDesignListInfo[]) {
        if (!designListInfos?.length)
            return;

        // Filter out designListInfo with the same designTypeId and add the new ones (they are being updated)
        this.designListInfo = this.designListInfo.filter(designList => designListInfos.every(x => x.designTypeId !== designList.designTypeId));
        this.addDesignListInfo(designListInfos);
    }

    public getDesignListInfo(): IDesignListInfo[] {
        this.ensureModulesLoaded();

        return this.designListInfo;
    }

    public getDesignListInfoByDesignType(designTypeId: number) {
        this.ensureModulesLoaded();

        return this.designListInfo.find((design) => design.designTypeId == designTypeId);
    }


    public addTrackingInfo(trackingInfo: ITrackingInfo[]) {
        if (!trackingInfo?.length) {
            return;
        }

        // Filter out designListInfo with the same designTypeId and add the new ones (they are being updated)
        this.trackingInfo = this.trackingInfo.filter(designList => trackingInfo.every(x => x.designTypeId !== designList.designTypeId));

        this.trackingInfo = this.trackingInfo.concat(trackingInfo);
    }

    public getTrackingInfo(designTypeId: number) {
        this.ensureModulesLoaded();

        return this.trackingInfo.find((design) => design.designTypeId == designTypeId);
    }


    public addDesignManagementInfo(designManagement: IDesignManagement[]) {
        if (!designManagement?.length) {
            return;
        }

        // Filter out designListInfo with the same designTypeId and add the new ones (they are being updated)
        this.designManagementInfo = this.designManagementInfo.filter(designList => designManagement.every(x => x.designTypeId !== designList.designTypeId));

        this.designManagementInfo = this.designManagementInfo.concat(designManagement);
    }

    public getDesignManagementInfo(designTypeId: number) {
        this.ensureModulesLoaded();

        return this.designManagementInfo.find((design) => design.designTypeId == designTypeId);
    }


    public addImportDesignProvider(importDesignProvider: IImportDesignProvider) {
        if (importDesignProvider) {
            this.importDesignProviders.push(importDesignProvider);
        }
    }

    public getImportDesignProviders(): IImportDesignProvider[] {
        this.ensureModulesLoaded();

        return this.importDesignProviders;
    }

    public addImportFileExtensions(importFileExtensions: string[]) {
        if (importFileExtensions.length) {
            this.importFileExtensions = this.importFileExtensions.concat(importFileExtensions);
        }
    }

    public getImportFileExtensions(): string[] {
        this.ensureModulesLoaded();

        return this.importFileExtensions;
    }


    public addAbpInfo(abpInfo: IAbpInfo) {
        if (abpInfo) {
            this.abpData.push(abpInfo);
        }
    }

    public isAdvancedCalculationPossible(designType: number, designStandard: number, region: number) {
        this.ensureModulesLoaded();

        const data = this.abpData.filter(x => x.isAdvancedCalculationPossible != null);
        for (const d of data) {
            const retVal = SafeFunctionInvokerHelper.safeInvoke(() => d.isAdvancedCalculationPossible(designType, designStandard, region), false);
            if (retVal) {
                return true;
            }
        }

        return false;
    }

    public isRigidityCheckPossible(designStandard: number, region: number) {
        this.ensureModulesLoaded();

        const data = this.abpData.filter(x => x.isRigidityCheckPossible != null);
        for (const d of data) {
            const retVal = SafeFunctionInvokerHelper.safeInvoke(() => d.isRigidityCheckPossible(designStandard, region), false);
            if (retVal) {
                return true;
            }
        }

        return false;
    }


    public addIntegrationsInfo(integrationsInfo: IIntegrationsInfo) {
        if (integrationsInfo) {
            this.integrationsData.push(integrationsInfo);
        }
    }

    public getQuickStartApplicationForIntegration(integrationType: DocumentIntegrationType): IQuickStartApplication | undefined {
        this.ensureModulesLoaded();

        const data = this.integrationsData.filter(x => x.getQuickStartApplicationForIntegration != null);
        for (const d of data) {
            const retVal = SafeFunctionInvokerHelper.safeInvoke(() => d.getQuickStartApplicationForIntegration(integrationType), undefined);
            if (retVal != null) {
                return retVal;
            }
        }

        return undefined;
    }


    public addDesignTypes(designTypes?: DesignTypeCommon[]) {
        if (designTypes?.length) {
            this.designTypesInternal.push(...designTypes);
            this.designTypesInternal.sort((a, b) => a.id - b.id);
        }
    }


    public addFavoritesInfo(favoritesInfo?: IFavoritesInfo) {
        if (favoritesInfo) {
            this.favoritesInfo.push(favoritesInfo);
        }
    }

    public getFavoritesInfo(): IFavoritesInfo[] {
        this.ensureModulesLoaded();

        return this.favoritesInfo;
    }

    public addUserSettingsInfo(userSettingsInfo?: IUserSettingsInfo) {
        if (userSettingsInfo) {
            this.userSettingsInfo.push(userSettingsInfo);
        }
    }

    public getUserSettingsInfo(): IUserSettingsInfo[] {
        this.ensureModulesLoaded();

        return this.userSettingsInfo;
    }

    public addModuleVersionInfoProvider(moduleVersionInfoProvider?: IModuleVersionInfoProvider) {
        if (moduleVersionInfoProvider) {
            this.moduleVersionInfoProviders.push(moduleVersionInfoProvider);
        }
    }

    public getModuleVersionInfoProviders(): IModuleVersionInfoProvider[] {
        this.ensureModulesLoaded();

        return this.moduleVersionInfoProviders;
    }

    public addInitialDataLoadedEvent(initialDataLoadedEvent?: () => Promise<void> | void) {
        if (initialDataLoadedEvent) {
            this.initialDataLoadedEvents.push(initialDataLoadedEvent);
        }
    }

    public addUserAuthenticatedEvent(userAuthenticatedEvent?: () => Promise<void> | void) {
        if (userAuthenticatedEvent) {
            this.userAuthenticatedEvents.push(userAuthenticatedEvent);
        }
    }

    public addTokenRefreshedEvent(tokenRefreshedEvent?: () => Promise<void> | void) {
        if (tokenRefreshedEvent) {
            this.tokenRefreshedEvents.push(tokenRefreshedEvent);
        }
    }

    public async initialDataLoaded() {
        this.ensureModulesLoaded();

        const promises: Promise<void>[] = [];

        for (const initialDataLoadedEvent of this.initialDataLoadedEvents) {
            promises.push(Promise.resolve(initialDataLoadedEvent()).catch(error => console.error(error)));
        }

        await Promise.all(promises);
    }

    public async userAuthenticated() {
        const promises: Promise<void>[] = [];

        for (const userAuthenticatedEvent of this.userAuthenticatedEvents) {
            promises.push(Promise.resolve(userAuthenticatedEvent()).catch(error => console.error(error)));
        }

        await Promise.all(promises);
    }

    public async tokenRefreshed() {
        const promises: Promise<void>[] = [];

        for (const tokenRefreshedEvent of this.tokenRefreshedEvents) {
            promises.push(Promise.resolve(tokenRefreshedEvent()).catch(error => console.error(error)));
        }

        await Promise.all(promises);
    }

    private ensureModulesLoaded(): void {
        if (!this.modulesLoaded) {
            throw new Error('Modules not loaded');
        }
    }

    public getProjectDesignFromDesign (design: Design, designTypeId: number) {
        if (design != null) {
            const designInfo = this.getDesignListInfoByDesignType(designTypeId);
            if (designInfo?.getProjectDesignFromDesign != null) {
                return SafeFunctionInvokerHelper.safeInvoke<object>(() => designInfo.getProjectDesignFromDesign(design));
            }
        }

        return undefined;
    }

    public async getDesignFileBytes (projectDesign: object, designTypeId: number) {
        if (projectDesign != null) {
            const designInfo = this.getDesignListInfoByDesignType(designTypeId);
            if (designInfo?.getDesignFileBytes != null) {
                const designBytes = await SafeFunctionInvokerHelper.safeInvoke<Promise<number[]>>(() => designInfo.getDesignFileBytes(JSON.stringify(projectDesign)));
                return designBytes ?? [];
            }
        }

        return undefined;
    }

    public async convertDesignsToPeFiles (designs: { [id: string]: string }, designTypeId: number) {
        const designInfo = this.getDesignListInfoByDesignType(designTypeId);
        if (designInfo?.convertDesignsToPeFiles != null) {
            return await SafeFunctionInvokerHelper.safeInvoke<Promise<{ [id: string]: string }>>(() => designInfo.convertDesignsToPeFiles(designs));
        }

        return designs;
    }
}
