import * as React from 'react';
import { ChangeEvent } from 'react';
import { isControlHidden } from '../../MainMenuHelper';
import { ISwitchWithDescriptionProps } from '@profis-engineering/pe-ui-common/entities/main-menu/switch-with-description';

export class SwitchWithDescription extends React.PureComponent<ISwitchWithDescriptionProps> {
    constructor(props?: ISwitchWithDescriptionProps) {
        super(props);

        this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
    }

    public override render() {
        if (isControlHidden(this.props)) {
            return null;
        }

        const {
            disabled, tooltipDisabled, controlId, image, showNewLabel, showBetaLabel, description, sizeClass, localization, title, checked
        } = this.props;

        const disableClassName = disabled ? 'disabled' : '';
        const tooltipText = disabled ? tooltipDisabled : null;
        const toggleId = `${controlId}-toggle`;
        const isFirstColumnVisible = image || showNewLabel || showBetaLabel;
        const shouldShowBetaLabel = !showNewLabel && showBetaLabel;
        const descriptionTooltip = !disabled && description ? stripHtmlTags(description) : null;

        function stripHtmlTags(str: string) {
            const div = document.createElement('div');
            div.innerHTML = str;
            return div.textContent || div.innerText || '';
        }

        const renderTagContainer = (label: string) => (
            <div className='tag-container'>
                <div className='tag overflow-ellipsis'>
                    {label}
                </div>
            </div>
        );

        return (
            <div data-control-id={controlId} data-tip={tooltipText} className={`react-switch-with-description control ${sizeClass}`}>
                <div className='content-holder'>
                    {isFirstColumnVisible && (
                        <div className="column first">
                            {image && (
                                <div className='image-container'>
                                    <span className={`sprite sprite-smart-design-control-icon`}></span>
                                </div>
                            )}

                            {showNewLabel && renderTagContainer(localization.getString('Agito.Hilti.Profis3.Navigation.Control.Title.New'))}
                            {shouldShowBetaLabel && renderTagContainer(localization.getString('Agito.Hilti.Profis3.Navigation.Control.Title.Beta'))}
                        </div>
                    )}

                    <div className="column middle">
                        <div className='title overflow-ellipsis' dangerouslySetInnerHTML={{ __html: title }}></div>
                        {description && (
                            <div data-tip={descriptionTooltip} className="description overflow-ellipsis" dangerouslySetInnerHTML={{ __html: description }}></div>
                        )}
                    </div>
                    <div className="column last">
                        <div className="toggle-container">
                            <input
                                type="checkbox"
                                id={toggleId}
                                checked={checked}
                                value={checked.toString()}
                                onChange={this.toggleCheckboxChange}
                                data-control-id={toggleId}
                                disabled={disabled}
                                className={`toggle-input ${disableClassName}`}
                            />
                            <label htmlFor={toggleId} className={`toggle-label ${disableClassName}`}></label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    toggleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
        if (!this.props?.disabled) {
            this.props?.checkChanged(event.target.checked);
        }
    }
}
