<div class="modal-header" id="design-from-template-dialog">
    <h3 class="modal-title" [l10n]="titleKey"></h3>

    <button type="button" id="design-from-template-close-button" class="close" (click)="close()" [disabled]="submitted">
        <span>&times;</span>
    </button>
</div>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
<div class="modal-body">
    <div class="content">
        <div class="project-type" *ngIf="isCompanyProjectVisible">
            <pe-radio-button class="control" [id]="projectType.id"
            [items]="projectType.items" [look] ="'Horizontal'"
            [selectedValue]="projectType.selectedValue"
            (selectedValueChange)=" selectedProjectTypeChange($any($event).detail)"
            [disabled]="submitted">
            </pe-radio-button>
        </div>
        <div class="project">
            <div class="project-dropdown control-row" *ngIf="!isProjectTextBoxDisplay">
                <app-dropdown [id]="projectDropdown.id" [title]="projectDropdown.title"
                    [items]="projectDropdown.items" [selectedValue]="projectDropdown.selectedValue"
                    [validators]="[requiredValidator]" (selectedValueChange)="onProjectDropdownSelectedValueChange($event)">
                </app-dropdown>
            </div>

            <div class="new-project-container control-row" *ngIf="isProjectTextBoxDisplay">
                <label class="control-label"
                    [l10n]="projectDropdownLabel"></label>

                <div class="input-container">
                    <div class="new-project">
                        <app-text-box [(value)]="newProject" [validators]="[requiredValidator]"
                            [maxLength]="250" [disabled]="submitted">
                        </app-text-box>
                    </div>

                    <button type="button" class="cancel-button" (click)="newProjectCancel()">
                        <span class="image sprite sprite-x-circle"></span>
                    </button>
                </div>
            </div>
         </div>
         <div class="name-text-box">
            <app-text-box [id]="nameTextBox.id" [title]="nameTextBox.title"
                [(value)]="nameTextBox.value" [validators]="nameTextBox.validators"
                [validationData]="nameTextBox.validationData" [maxLength]="250"
                [disabled]="submitted">
            </app-text-box>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" id="design-from-template-cancel-button"
    class="modal-button button button-default" l10n="Agito.Hilti.Profis3.HomePage.Popup.Cancel" (click)="close()"
    [disabled]="submitted">
    </button>

    <button type="submit" id="design-from-template-save-button" class="modal-button button button-CTA"
        [disabled]="(ngForm.enabled && !ngForm.valid) || submitted">
        <pe-loading-button [loading]="submitted"
            [text]="'Agito.Hilti.Profis3.HomePage.Popup.Save' | l10n:localization.selectedLanguage">
        </pe-loading-button>
    </button>
</div>
</form>
