import { inject } from '@angular/core';

import { AuthenticationService } from '../services/authentication.service';
import { RoutingService } from '../services/routing.service';
import { UserService } from '../services/user.service';
import { GuardFactory } from './combined.guard';

export const authenticationGuard: GuardFactory = (handleGuardAction) => {
    const userService = inject(UserService);
    const authenticationService = inject(AuthenticationService);
    const routingService = inject(RoutingService);

    return () => handleGuardAction(async () => {
        await userService.authenticatedFromStorage();

        if (!userService.isAuthenticated) {
            await authenticationService.login(routingService.currentPath);
        }

        return true;
    });
};
