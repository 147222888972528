import escape from 'lodash-es/escape';

import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
    ErrorDetails, IApplicationError
} from '@profis-engineering/pe-ui-common/entities/application-error';
import {
    ModalInstance, MODAL_DISMISS_REASON_BACKDROP, MODAL_DISMISS_REASON_ESC
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ISignalRError } from '@profis-engineering/pe-ui-common/services/signalr.common';
import {
    MessageResponseType
} from '@profis-engineering/pe-ui-shared/generated-modules/Hilti.PE.Core.Common.Enums';
import { AuthenticationService } from '../../services/authentication.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';

export const enum AlertType {
    Unknown = 0,

    Warning = 1,
    Error = 2,

    GLError = 10,

    ServiceError = 20,

    SignalRError = 30,

    LicenseError = 40
}

export interface IAlertComponentInput {
    type: AlertType;
    title?: string;
    message?: string;
    applicationError?: IApplicationError;
}

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    public title: string;
    public message: string;
    public detailedError: string;
    public showSupportButton = true;
    public showLogoutButton = false;
    public showLearnMore = false;
    public preventClose = false;
    public isCollapsed = true;

    private applicationError: IApplicationError;

    constructor(
        private readonly localization: LocalizationService,
        private readonly modal: ModalService,
        private readonly authentication: AuthenticationService,
        private modalInstance: ModalInstance<IAlertComponentInput>,
        private readonly commonTrackingService: CommonTrackingService,
    ) { }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing((result) => {
            if (this.preventClose && (result == MODAL_DISMISS_REASON_BACKDROP || result == MODAL_DISMISS_REASON_ESC)) {
                return false;
            }

            return true;
        });

        // Get field values
        let title = '';
        let message = '';
        let applicationError: IApplicationError;
        if (this.modalInstance.input != null) {
            if (this.modalInstance.input.applicationError != null) {
                applicationError = this.modalInstance.input.applicationError;
            }

            switch (this.modalInstance.input.type) {
                case AlertType.Warning:
                    // Title and message already specified
                    // This is user-related error; do not show support button
                    title = this.modalInstance.input.title;
                    message = this.modalInstance.input.message;
                    this.showSupportButton = false;
                    break;

                case AlertType.Error:
                    // Title and message already specified
                    title = this.modalInstance.input.title;
                    message = this.modalInstance.input.message;
                    break;

                case AlertType.GLError:
                    // GL error
                    // Do not sshow support button, prevent close
                    title = this.localization.getString('Agito.Hilti.Profis3.3DErrorAlert.Title', { defaultString: 'ERROR' });
                    message = this.localization.getString('Agito.Hilti.Profis3.3DErrorAlert.Message', { defaultString: 'Your browser has disabled 3D rendering. Please wait a moment or refresh the website to continue.' });
                    this.showSupportButton = false;
                    this.preventClose = true;
                    break;

                case AlertType.ServiceError:
                    // Service error; title and message need to be obtained from applicationError
                    [title, message] = this.getServiceErrorFieldValues(applicationError);
                    this.showLearnMore = this.detailedError != undefined && this.detailedError != '';
                    break;

                case AlertType.SignalRError:
                    // SignalR error; title and message need to be obtained from applicationError
                    [title, message] = this.getSignalRErrorFieldValues(applicationError);
                    this.showLearnMore = this.detailedError != undefined && this.detailedError != '';
                    break;

                case AlertType.LicenseError:
                    // License error
                    // Show logout button
                    title = this.localization.getString('Agito.Hilti.Profis3.LicenseErrorAlert.Title', { defaultString: 'UNKNOWN LICENSE' });
                    message = this.localization.getString('Agito.Hilti.Profis3.LicenseErrorAlert.Message', { defaultString: 'We could not identify your license type. <br> You are currently using standard license, if you think you should have a different type of license, please try to re-login in a few minutes or contact Hilti.' });
                    this.showSupportButton = false;
                    this.showLogoutButton = true;
                    break;

                case AlertType.Unknown:
                default:
                    break;
            }
        }

        // And set them
        this.setFieldValues(title, message, applicationError);

        // Track the error
        if(this.modalInstance.input.type != AlertType.Warning) {
            this.commonTrackingService.trackOnActivity({
                ErrorType: this.getErrorTypeText(this.modalInstance.input.type),
                ErrorMessage: this.detailedError ?? message
            });
        }
    }

    public close() {
        this.modalInstance.close();
    }

    public logout() {
        this.authentication.logout();
    }

    public openSupport() {
        // open modal for support after modal for alert is closed
        new Promise<void>((resolve, reject) => {
            try {
                this.close();
                resolve();
            }
            catch {
                reject();
            }
        })
            .then(() => this.modal.openSupport(this.applicationError))
            .catch((err: any) => console.error(err));
    }

    public toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    private getServiceErrorFieldValues(applicationError: IApplicationError) {
        if (applicationError.response instanceof Error) {
            // do not log the error into console (duplicate console log)
            // the one calling alert.open should log the error if needed
        }

        this.setErrorDetails(applicationError);

        if (applicationError.endPointUrl == null && applicationError.response instanceof HttpResponseBase) {
            applicationError.endPointUrl = applicationError.response.url;
        }

        const httpBody = applicationError.response instanceof HttpErrorResponse && applicationError.response.status > 0
            ? applicationError.response.error
            : applicationError.response instanceof HttpResponse
                ? applicationError.response.body
                : undefined;

        const defaultTitle = this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.Title', { defaultString: 'SORRY, THERE IS AN ERROR' });

        // check if it's a message type
        if (httpBody != null
            && httpBody.Type != null
            && httpBody.Message != null
            && httpBody.Message != ''
        ) {
            return this.handleTitleBodyMessage(httpBody, defaultTitle);
        }

        const defaultMessage = this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.NoConnectionDescription', { defaultString: 'The connection to the server could not be established. Please check your internet connection and try again later. If the problem persists contact us.' });
        return this.handleGeneralError(httpBody, defaultTitle, defaultMessage, applicationError);
    }

    private handleTitleBodyMessage(httpBody: any, defaultTitle: string) {
        let title = httpBody.Title;
        let message = httpBody.Message;

        switch (httpBody.Type) {
            case MessageResponseType.Message:
                if (title == null || title == '') {
                    title = defaultTitle;
                }
                break;

            case MessageResponseType.Translation:
                message = this.localization.getString(message);

                if (title == null || title == '') {
                    title = defaultTitle;
                }
                else {
                    title = this.localization.getString(title);
                }
                break;

            default:
                throw new Error('Unsupported message response type.');
        }

        return [title, message];
    }

    private handleGeneralError(httpBody: any, defaultTitle: string, defaultMessage: string, applicationError: IApplicationError) {
        const title = defaultTitle;
        const message = escape(defaultMessage);
        if (applicationError.response != null && applicationError.response instanceof HttpResponseBase) {
            if (httpBody?.Message && typeof httpBody.Message == 'string') {
                // message was returned, let's display it
                this.detailedError = httpBody.Message;
            }
        }

        return [title, message];
    }

    private getSignalRErrorFieldValues(applicationError: IApplicationError) {
        if (applicationError.response instanceof Error) {
            console.error(applicationError.response);
        }

        let errorMessage = null;
        let statusCode = null;
        let response = null;

        if (typeof applicationError.response == 'string') {
            response = applicationError.responsePayload;
        }
        else {
            response = applicationError.response;
        }

        if (response != null && (response as ISignalRError).data != undefined) {
            response = response as ISignalRError;
            errorMessage = response.message
                ? response.message
                : response.data.ExceptionMessage;
            statusCode = response.data.StatusCode
                ? response.data.StatusCode.toString()
                : null;
        }

        if (errorMessage != null || statusCode != null) {
            applicationError.errors = [{
                errorMessage,
                statusCode
            } as ErrorDetails];
        }

        const defaultTitle = this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.Title', { defaultString: 'SORRY, THERE IS AN ERROR' });
        const defaultMessage = this.localization.getString('Agito.Hilti.Profis3.ServerErrorAlert.NoConnectionDescription', { defaultString: 'The connection to the server could not be established. Please check your internet connection and try again later. If the problem persists contact us.' });

        const title = defaultTitle;
        const message = escape(defaultMessage);

        if (applicationError.response != null) {
            if (typeof applicationError.response == 'string') {
                if (applicationError.response) {
                    this.detailedError = applicationError.response;
                }
            }
            else if ((applicationError.response as ISignalRError).message != undefined) {
                this.detailedError = (applicationError.response as ISignalRError).message;
            }
        }

        return [title, message];
    }

    private setFieldValues(title: string, message: string, applicationError: IApplicationError) {
        // Title
        this.title = title != null && title != ''
            ? escape(title)
            : '&nbsp;';


        // Message
        if (message == null || message == '') {
            this.message = '&nbsp;';
        }
        else if (this.localization.isHtml(message)) {
            message = message.replace(this.localization.htmlStartTagRegExp, '');
            this.message = message.replace(this.localization.htmlEndTagRegExp, '');
        }
        else {
            this.message = escape(message);
        }


        // Application error
        if (applicationError?.response != null) {
            if (applicationError.errors == null) {
                applicationError.errors = [];
            }

            if (applicationError.response instanceof Error) {
                if (applicationError.errors.length == 0) {
                    applicationError.errors.push({
                        errorMessage: applicationError.response.message,
                        errorStackTrace: applicationError.response.stack
                    });
                }
            }

            if (applicationError.endPointUrl == null
                && applicationError.response instanceof HttpResponseBase
            ) {
                applicationError.endPointUrl = applicationError.response.url;
            }

            if (applicationError.responsePayload == null && applicationError.response != null) {
                applicationError.responsePayload = applicationError.response;
            }
        }
        this.applicationError = applicationError;
    }

    private setErrorDetails(applicationError: IApplicationError) {
        if (applicationError == null) {
            return;
        }

        let errorMessage = null;
        let errorStackTrace = null;
        let statusCode = null;

        if (applicationError.response instanceof Error) {
            errorMessage = applicationError.response.message;
            errorStackTrace = applicationError.response.stack;
        }
        else if (applicationError.response instanceof HttpErrorResponse && applicationError.response.error instanceof Error) {
            const error = applicationError.response.error;

            errorMessage = error.message;
            errorStackTrace = error.stack;
        }
        else if (applicationError.response instanceof HttpErrorResponse && applicationError.response.status > 0) {
            const response = applicationError.response;

            errorMessage = response.error?.Message ?? response.statusText;
            statusCode = response.status.toString();
        }
        else if (applicationError.response instanceof HttpResponse) {
            const response = applicationError.response;

            errorMessage = response.body?.Message ?? response.statusText;
            statusCode = response.status.toString();
        }

        if (errorMessage != null || errorStackTrace != null || statusCode != null) {
            applicationError.errors = [{
                errorMessage,
                errorStackTrace,
                statusCode
            }];
        }
    }

    private getErrorTypeText(type: AlertType): string {
        switch (type) {
            case AlertType.Warning:
                return 'Warning';
            case AlertType.Error:
                return 'Error';
            case AlertType.GLError:
                return 'GLError';
            case AlertType.ServiceError:
                return 'ServiceError';
            case AlertType.SignalRError:
                return 'SignalRError';
            case AlertType.LicenseError:
                return 'LicenseError';
            case AlertType.Unknown:
            default:
                return 'Unknown';
        }
    }
}
