<div class="chatbot-window" *ngIf="isEnabled">
    <div class="chat-header">
      <div class="sprite sprite-robot-white"></div>

      <span class="chat-name">ASK SMARTIN</span>
      <div class="chat-actions">
            <button *ngIf="openWindow" class="button-delete collapse-button chat-clear" (click)="clear()">
                <div class="sprite sprite-trash-white"></div>
            </button>
            <button *ngIf="openWindow" type="button" id="minimize-chat-button" class="chat-close close" (click)="close()">
                <span>&times;</span>
            </button>
            <button *ngIf="!openWindow" type="button" id="minimize-chat-button" class="button-open collapse-button chat-open" (click)="open()">
                <div class="sprite sprite-arrow-up-white"></div>
            </button>
      </div>
    </div>
    <div class="chat-wrapper" *ngIf="openWindow">
        <div class="chat-no-messages" *ngIf="showEmpyState">
            <img [src]="smartinImage" alt="smartin image" />
            <div class="chat-no-messages-text">
                <p>
                    Hi! I'm Smartin, your virtual assistant!<br/>
                    I'm here to help with product, design methods and software features questions.<br/><br/>
                    How can I help you today?
                </p>
            </div>
        </div>
        <div class="chat-messages" *ngIf="!showEmpyState" #chatMessages [scrollTop]="chatMessages.scrollHeight" >
            <div #messages *ngFor="let qa of questionAnwsers">
                <div class="chat-message">
                    <div class="question-anwser  question">{{ qa.question }}</div>
                </div>
                <div class="chat-message" *ngIf="qa.answer">
                    <div class="question-anwser  answer">
                        {{ qa.answer }}
                        <div class="answer-actions">
                            <button class="like-button" (click)="likeAnswer(qa)">
                                <i class="sprite sprite-hilti-styled-notification-ok-small"></i>
                            </button>
                            <button class="dislike-button" (click)="dislikeAnswer(qa)">
                                <i class="sprite sprite-notification-alert-small"></i>
                            </button>
                        </div>
                        <div *ngIf="qa.showDislikeInput" class="dislike-input">
                            <input type="text" [(ngModel)]="qa.dislikeComment" placeholder="Why didn't you like the answer?" />
                            <button (click)="submitDislike(qa)">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="chat-input">
            <div class="text-input-wrapper">
                <pe-text-box
                    id="chat-question"
                    [placeholder]="'Type a message...'"
                    [value]="newQuestion"
                    (valueChange)="newQuestion = $any($event).detail"

                    (enterPressed)="send()"
                    [marginBottom]="'None'">
                </pe-text-box>
            </div>
            <pe-button
                [id]="'send-message-button'"
                class="modal-button"
                [look]="'Cta'"
                (buttonClick)="send()"
                [disabled]="waitingForResponse">
                <pe-loading-button [loading]="waitingForResponse" [text]="'Send'">
                </pe-loading-button>
            </pe-button>
        </div>
    </div>
</div>