import { provideEnvironmentNgxMask } from 'ngx-mask';
import { SortablejsModule } from 'ngx-sortablejs';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NgbDropdownModule, NgbModalModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { Userpilot } from 'userpilot';
import { environment } from '../environments/environment';
import { AddEditDesignComponent } from './components/add-edit-design/add-edit-design.component';
import { AdvancedCalculationInputsComponent } from './components/advanced-calculation-inputs/advanced-calculation-inputs.component';
import { AlertTestingEnvironemtComponent } from './components/alert-testing-environment/alert-testing-environment.component';
import { AlertComponent } from './components/alert/alert.component';
import { AppSettingsAdvancedBaseplateCalculationComponent } from './components/app-settings-advanced-baseplate-calculation/app-settings-advanced-baseplate-calculation.component';
import { AppSettingsDefaultParametersComponent } from './components/app-settings-default-parameters/app-settings-default-parameters.component';
import { AppSettingsInteractionSettingsComponent } from './components/app-settings-interaction-settings/app-settings-interaction-settings.component';
import { AppSettingsRegionLanguageComponent } from './components/app-settings-region-language/app-settings-region-language.component';
import { AppSettingsStructuralCalculationSoftwareComponent } from './components/app-settings-structural-calculation-software/app-settings-structural-calculation-software.component';
import { AppSettingsComponent } from './components/app-settings/app-settings.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { DesignFromCheckbotComponent } from './components/checkbot/design-from-checkbot/design-from-checkbot.component';
import { ImportFromCheckbotComponent } from './components/checkbot/import-from-checkbot/import-from-checkbot.component';
import { ConfirmChangeComponent } from './components/confirm-change/confirm-change.component';
import { CopyDesignComponent } from './components/copy-design/copy-design.component';
import { DesktopLicenseWarningComponent } from './components/desktop-license-warning/desktop-license-warning.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ErrorComponent } from './components/error/error.component';
import { ExportReportsComponent } from './components/export-reports/export-reports.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { GeneralNotesComponent } from './components/general-notes/general-notes.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { ArchiveHomePageComponent } from './components/home-page/archive/archive.component';
import { ConfirmChangeInputComponent } from './components/home-page/confirm-change-input/confirm-change-input.component';
import { DesignFromTemplateComponent } from './components/home-page/design-from-template/design-from-template.component';
import { DesignViewComponent } from './components/home-page/design-view/design-view.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ImportDesignsComponent } from './components/home-page/import-designs/import-designs.component';
import { QuickStartComponent } from './components/home-page/quick-start/quick-start.component';
import { RightPanelComponent } from './components/home-page/right-panel/right-panel.component';
import { ShareTemplateFolderComponent } from './components/home-page/share-template-folder/share-template-folder.component';
import { ShareViewComponent } from './components/home-page/share-view/share-view.component';
import { TreeViewComponent } from './components/home-page/tree-view/tree-view.component';
import { HomeSwitchComponent } from './components/home-switch/home-switch.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { ImportDesignComponent } from './components/import-design/import-design.component';
import { LaunchDarklyFlagsComponent } from './components/launch-darkly-flags/launch-darkly-flags.component';
import { LicenseComparisonHolComponent } from './components/license-comparison-hol/license-comparison-hol.component';
import { LicenseComparisonTableComponent } from './components/license-comparison-table/license-comparison-table.component';
import { LicenseComparisonComponent } from './components/license-comparison/license-comparison.component';
import { LoadsMessagesComponent } from './components/loads-messages/loads-messages.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { MainComponent } from './components/main/main.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { MarketingCampaignComponent } from './components/marketing-campaign/marketing-campaign.component';
import { ModuleQuickStartButtonComponent } from './components/module-quick-start-button/module-quick-start-button.component';
import { NoopComponent } from './components/noop/noop.component';
import { NpsSurveyPopupComponent } from './components/nps-survey-popup/nps-survey-popup.component';
import { ProjectAndDesignComponent } from './components/project-and-design/project-and-design.component';
import { RenameDesignComponent } from './components/rename-design/rename-design.component';
import { ReportTemplatesComponent } from './components/report-templates/report-templates.component';
import { RootComponent } from './components/root/root.component';
import { SaveAsTemplateComponent } from './components/save-as-template/save-as-template.component';
import { SelectRegionLanguageComponent } from './components/select-region-language/select-region-language.component';
import { ShareProjectComponent } from './components/share-project/share-project.component';
import { ShortcutIconModalComponent } from './components/shortcut-icon-modal/shortcut-icon-modal.component';
import { SmartinChatbotComponent } from './components/smartin-chatbot/smartin-chatbot.component';
import { SupportComponent } from './components/support/support.component';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { TrialBannerComponent } from './components/trial-banner/trial-banner.component';
import { TrimbleConnectBrowserTreeNodeComponent } from './components/trimble-connect-browser-tree-node/trimble-connect-browser-tree-node.component';
import { TrimbleConnectBrowserComponent } from './components/trimble-connect-browser/trimble-connect-browser.component';
import { UnauthorizedAccessComponent } from './components/unauthorized-access/unauthorized-access.component';
import { UserAgreementPrivacyComponent } from './components/user-agreement-privacy/user-agreement-privacy.component';
import { UserAgreementSettingsComponent } from './components/user-agreement-settings/user-agreement-settings.component';
import { UserAgreementComponent } from './components/user-agreement/user-agreement.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { VersionPopupComponent } from './components/version-popup/version-popup.component';
import { VersionComponent } from './components/version/version.component';
import { VirtualTourPopupComponent } from './components/virtual-tour-popup/virtual-tour-popup.component';
import { WhatsNewComponent } from './components/whats-new/whats-new.component';
import { DeckingDeclarations } from './decking/entities/decking-components';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { DragDirective } from './directives/drag.directive';
import { DropDirective } from './directives/drop.directive';
import { GenericModalComponentRefDirective } from './directives/generic-modal-component-ref.directive';
import { L10nDirective } from './directives/l10n.directive';
import { httpInterceptorProviders } from './http-interceptors';
import { AppRoutingModule } from './modules/app-routing.module';
import { L10nPipe } from './pipes/l10n.pipe';
import { ComponentProviderService, DeclarationType } from './services/component-provider.service';
import { DesignTemplateServiceImpl } from './services/design-template-impl.service';
import { DesignTemplateService } from './services/design-template.service';
import { DocumentServiceImpl } from './services/document-impl.service';
import { DocumentService } from './services/document.service';
import { ModulesService } from './services/modules.service';
import { OfflineServiceImpl } from './services/offline-impl.service';
import { OfflineService } from './services/offline.service';
import { ProductInformationImpl } from './services/product-information-impl.service';
import { ProductInformationService } from './services/product-information.service';
import { QueryService } from './services/query.service';
import { RemoteLoggingServiceImpl } from './services/remote-logging-impl.service';
import { RemoteLoggingService } from './services/remote-logging.service';
import { ReportTemplateServiceImpl } from './services/report-template-impl.service';
import { ReportTemplateService } from './services/report-template.service';
import { UserSettingsServiceImpl } from './services/user-settings-impl.service';
import { UserSettingsService } from './services/user-settings.service';
import { UserService } from './services/user.service';

const sharedAuthenticationKey = 'sharedAuthentication';
const authenticationKey = 'authentication';

// When changing this list also change the DeclarationType enum in src\app\services\component-provider.service.ts.
// This declarations list and the DeclarationType enum in component-provider.service *MUST* be the same!!!
// Order in declarations and component-provider.service MUST be the same!!!
// Also, the entry in this list **should be the same as the component's class name (no aliases!)**!
const declarations = [
    TextBoxComponent,
    UserSettingsComponent,
    L10nDirective,
    DragDirective,
    DropDirective,
    ClickStopPropagationDirective,
    DropdownComponent,
    ReportTemplatesComponent,
    GenericModalComponentRefDirective,
    L10nPipe,
    ExportReportsComponent,
    AppSettingsComponent,
    AppSettingsRegionLanguageComponent,
    AppSettingsInteractionSettingsComponent,
    UserAgreementSettingsComponent,
    TrialBannerComponent,
    LicenseComparisonComponent,
    LicenseComparisonTableComponent,
    ConfirmChangeComponent,
    UnauthorizedAccessComponent,
    UserAgreementComponent,
    UserAgreementPrivacyComponent,
    WhatsNewComponent,
    SelectRegionLanguageComponent,
    GeneralNotesComponent,
    LoadsMessagesComponent,
    ShortcutIconModalComponent,
    ImageModalComponent,
    MarketingCampaignComponent,
    ArchiveComponent,
    SaveAsTemplateComponent,
    RenameDesignComponent,
    CopyDesignComponent,
    ShareProjectComponent,
    ShareTemplateFolderComponent,
    ShareViewComponent,
    SupportComponent,
    AlertComponent,
    AlertTestingEnvironemtComponent,
    NoopComponent,
    ErrorComponent,
    ProjectAndDesignComponent,
    MainComponent,
    RootComponent,
    NpsSurveyPopupComponent,
    TrimbleConnectBrowserTreeNodeComponent,
    TrimbleConnectBrowserComponent,
    VirtualTourPopupComponent,
    AddEditDesignComponent,
    MainMenuComponent,
    ImportDesignComponent,
    DesktopLicenseWarningComponent,
    GenericModalComponent,
    DesignFromCheckbotComponent,
    MaintenanceComponent,
    AppSettingsStructuralCalculationSoftwareComponent,
    AppSettingsDefaultParametersComponent,
    AppSettingsAdvancedBaseplateCalculationComponent,
    AdvancedCalculationInputsComponent,
    LicenseComparisonHolComponent,
    FeedbackFormComponent,
    VersionComponent,
    VersionPopupComponent,
    HomePageComponent,
    ImportDesignsComponent,
    TreeViewComponent,
    ArchiveHomePageComponent,
    ConfirmChangeInputComponent,
    LaunchDarklyFlagsComponent,
    RightPanelComponent,
    DesignViewComponent,
    QuickStartComponent,
    ModuleQuickStartButtonComponent,
    DesignFromTemplateComponent,
    HomeSwitchComponent,
    ImportFromCheckbotComponent,
    SmartinChatbotComponent,
];

export const moduleComponentDeclarations = declarations.map((val, index) => [index, val.name] as Record<number, string>);

const componentDeclarations = Object.fromEntries(declarations.map((val, index) => [index, val])) as Record<DeclarationType, Type<object>>;
const declarationsList = [...declarations, DeckingDeclarations];

@NgModule({
    declarations: declarationsList,
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbTooltipModule,
        NgbDropdownModule,
        NgbModalModule,
        HttpClientModule,
        VirtualScrollerModule,
        SortablejsModule.forRoot({}),
        NgxSliderModule
    ],
    providers: [
        httpInterceptorProviders,
        provideEnvironmentNgxMask(),
        { provide: DocumentService, useClass: DocumentServiceImpl },
        { provide: UserSettingsService, useClass: UserSettingsServiceImpl },
        { provide: OfflineService, useClass: OfflineServiceImpl },
        { provide: ProductInformationService, useClass: ProductInformationImpl },
        { provide: ReportTemplateService, useClass: ReportTemplateServiceImpl },
        { provide: DesignTemplateService, useClass: DesignTemplateServiceImpl },
        { provide: RemoteLoggingService, useClass: RemoteLoggingServiceImpl }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [RootComponent]
})
export class AppModule {
    constructor(
        private injector: Injector,
        private userService: UserService,
        meta: Meta,
        query: QueryService,
        ngbTooltipConfig: NgbTooltipConfig,
        componentProvider: ComponentProviderService,
        modulesService: ModulesService
    ) {
        this.registerCustomElement('main-menu', MainMenuComponent);

        this.handleOriginTrialKeys(meta);
        this.configureTooltip(ngbTooltipConfig);
        this.handleAccessKeyChange();

        componentProvider.register(componentDeclarations);
        query.storeInitialQuery();

        modulesService.bootstrapModules();

        userService.authenticated.subscribe(() => {
            this.initUserpilot();
        });
    }

    private initUserpilot() {
        if (this.userService.authentication.userId) {
            Userpilot.identify(
                this.userService.authentication.userId,
                {
                    name: this.userService.authentication.userName,
                    email: this.userService.authentication.userName,
                    license: this.userService.authentication.license,
                    customerId: this.userService.authentication.customerId,
                    customerOriginId: this.userService.authentication.customerOriginId,
                    contactId: this.userService.authentication.contactId,
                    userCountry: this.userService.authentication.country,
                    userCountryOfResidence: this.userService.authentication.countryOfResidence,
                }
            )
        }
    }

    private handleOriginTrialKeys(metaSvc: Meta) {
        if (environment.originTrialKeys.length > 0) {
            environment.originTrialKeys.forEach(key => {
                metaSvc.addTag({
                    // name: 'origin-trial',
                    'http-equiv': 'origin-trial',
                    content: key
                });
            });
        }
    }

    private configureTooltip(config: NgbTooltipConfig) {
        config.container = 'body';
        config.triggers = 'hover';
        config.openDelay = 500;
    }

    private handleAccessKeyChange() {
        window.addEventListener('storage', async (event) => {
            if (event.key === sharedAuthenticationKey) {
                // if this tab needs data
                if (event.newValue) {
                    const currentAuthenticationJson = window.sessionStorage.getItem(authenticationKey);
                    const currentAuthentication: Record<string, unknown> = currentAuthenticationJson != null ? JSON.parse(currentAuthenticationJson) : {};
                    const newAuthentication = JSON.parse(event.newValue);
                    if (currentAuthentication['userId'] == newAuthentication['userId']) {
                        window.sessionStorage.setItem(authenticationKey, event.newValue);
                        await this.userService.authenticatedFromStorage();
                    }
                }
            }
        }, false);
    }

    private registerCustomElement(componentName: string, component: Type<any>) {
        const customElement = createCustomElement(component, { injector: this.injector });
        customElements.define(`pe-${componentName}`, customElement);
    }
}
