<div class="modal-header" id="add-edit-design-selectconnapp-dialog">
    <h3 class="modal-title" [l10n]="titleKey"></h3>

    <button type="button" id="add-edit-design-close-button" class="close" (click)="close()" [disabled]="submitted">
        <span>&times;</span>
    </button>
</div>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <div class="modal-body">
        <div *ngIf="step == stepEnum.basic">
            <div class="section type-general">
                <div class="section-row">
                    <div class="section-column type" *ngIf="showLayout4">
                        <label class="control-label" l10n="Agito.Hilti.Profis3.AddEditDesign.Type"></label>

                        <div class="content">
                            <ng-container *ngFor="let designInfo of designInfoData">
                                <div id="{{designInfo.id}}" *ngIf="isDesignInfoAvailable(designInfo)"
                                    class="image-container"
                                    [class.disabled]="submitted || isDesignInfoDisabled(designInfo)"
                                    [class.selected4]="selectedModuleDesignInfo?.designTypeId == designInfo.designTypeId"
                                    (click)="designInfoClick(designInfo)"
                                    [ngbTooltip]="getNewDesignInfoButtonTooltip(designInfo)">
                                    <span class="image sprite sprite-responsive"
                                        [ngStyle]="designInfoImageStyle(designInfo)"></span>
                                    <span class="name">{{ designInfo.title ?? translate(designInfo.titleTranslationKey) }}</span>
                                </div>
                            </ng-container>

                            <div id="add-edit-design-image-container-diaphragm-design" *ngIf="deckingEnabled"
                                class="image-container" [class.selected]="designType?.id == designTypeIdEnum.DiaphragmDesign"
                                [class.disabled]="isDiaphragmDesignDisabled()"
                                (click)="!isDiaphragmDesignDisabled() && designTypeClick(designTypeIdEnum.DiaphragmDesign)">
                                <span class="image sprite sprite-responsive sprite-decking-design"></span>
                                <span class="name" l10n="Agito.Hilti.Profis3.AddEditDesign.Type.DiaphragmDesign"></span>
                            </div>
                        </div>
                    </div>

                    <div class="section-column general">
                        <div class="content">
                            <div class="name-text-box">
                                <app-text-box [id]="nameTextBox.id" [title]="nameTextBox.title"
                                    [(value)]="nameTextBox.value" [validators]="nameTextBox.validators"
                                    [validationData]="nameTextBox.validationData" [maxLength]="250"
                                    [disabled]="submitted || isNameTextBoxDisabled">
                                </app-text-box>
                            </div>

                            <div class="project-type">
                                <pe-radio-button class="control" *ngIf="showProjectTypeRadioButton" [id]="projectType.id"
                                [items]="projectType.items" [look] ="'Horizontal'"
                                [selectedValue]="projectType.selectedValue"
                                (selectedValueChange)=" selectedProjectTypeChange($any($event).detail)"
                                [disabled]="submitted || this.isProjectDropDownDisabled">
                                </pe-radio-button>
                            </div>

                            <div class="two-columns">
                                <div class="project-dropdown control-row" *ngIf="isProjectDropdownDisplay">
                                    <app-dropdown [id]="projectDropdown.id" [title]="projectDropdown.title"
                                        [items]="projectDropdown.items" [selectedValue]="projectDropdown.selectedValue"
                                        (selectedValueChange)="onProjectDropdownSelectedValueChange($event)"
                                        [validators]="[requiredValidator]" [disabled]="submitted || isProjectDropDownDisabled">
                                    </app-dropdown>
                                </div>

                                <div class="project-dropdown control-row" *ngIf="isTemplateFolderDdlDisplay">
                                    <app-dropdown [id]="templateFolderDropdown.id" [title]="templateFolderDropdown.title"
                                        [items]="templateFolderDropdown.items" [selectedValue]="templateFolderDropdown.selectedValue"
                                        (selectedValueChange)="onTemplateFolderDropdownSelectedValueChange($event)"
                                        [validators]="[requiredValidator]" [disabled]="submitted || isTemplateFolderDropDownDisabled">
                                    </app-dropdown>
                                </div>

                                <div class="new-project-container control-row" *ngIf="isProjectTextBoxDisplay">
                                    <label class="control-label"
                                        [l10n]="projectDropdownLabelKey"></label>

                                    <div class="input-container">
                                        <div class="new-project">
                                            <app-text-box [(value)]="newProject" [validators]="[requiredValidator]"
                                                [maxLength]="250" [disabled]="submitted">
                                            </app-text-box>
                                        </div>

                                        <button type="button" class="cancel-button" (click)="newProjectCancel()"
                                            [disabled]="submitted">
                                            <span class="image sprite sprite-x-circle"></span>
                                        </button>
                                    </div>
                                </div>

                                <div class="new-project-container control-row" *ngIf="isTemplateFolderTextboxDisplay">
                                    <label class="control-label"
                                        l10n="Agito.Hilti.Profis3.HomePage.TemplateFolder"></label>

                                    <div class="input-container">
                                        <div class="new-project">
                                            <app-text-box [(value)]="newTemplateFolder" [validators]="[requiredValidator]"
                                                [maxLength]="250" [disabled]="submitted">
                                            </app-text-box>
                                        </div>

                                        <button type="button" class="cancel-button" (click)="newTemplateFolderCancel()"
                                            [disabled]="submitted">
                                            <span class="image sprite sprite-x-circle"></span>
                                        </button>
                                    </div>
                                </div>

                                <div class="region-dropdown control-row">
                                    <app-dropdown [id]="regionDropdown.id" [title]="regionDropdown.title"
                                        [items]="regionDropdown.items" [selectedValue]="regionDropdown.selectedValue"
                                        (selectedValueChange)="onRegionDropdownSelectedValueChange($event)"
                                        [validators]="[requiredValidator]"
                                        [disabled]="submitted || addEditType == addEditTypeEnum.edit || this.isFromCheckBotDisplay || mockIsEditDiaphragmDesign">
                                    </app-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-column type" *ngIf="showLayoutAll">
                    <label class="control-label" l10n="Agito.Hilti.Profis3.AddEditDesign.Type"></label>

                    <div class="content all">
                        <ng-container *ngFor="let designInfo of designInfoData">
                            <div id="{{designInfo.id}}" *ngIf="isDesignInfoAvailable(designInfo)" class="image-container"
                                [class.disabled]="submitted || isDesignInfoDisabled(designInfo)"
                                [class.selected]="selectedModuleDesignInfo?.id == designInfo.id"
                                (click)="designInfoClick(designInfo)"
                                [ngbTooltip]="getNewDesignInfoButtonTooltip(designInfo)">
                                <span class="image sprite sprite-responsive"
                                    [ngStyle]="designInfoImageStyle(designInfo)"></span>
                                <span class="name">{{ designInfo.title ?? translate(designInfo.titleTranslationKey) }}</span>
                            </div>
                        </ng-container>

                        <div id="add-edit-design-image-container-diaphragm-design" *ngIf="deckingEnabled"
                            class="image-container" [class.selected]="designType?.id == designTypeIdEnum.DiaphragmDesign"
                            [class.disabled]="isDiaphragmDesignDisabled()"
                            (click)="!isDiaphragmDesignDisabled() && designTypeClick(designTypeIdEnum.DiaphragmDesign)">
                            <span class="image sprite sprite-responsive sprite-decking-design"></span>
                            <span class="name" l10n="Agito.Hilti.Profis3.AddEditDesign.Type.DiaphragmDesign"></span>
                        </div>
                    </div>
                </div>

                <div class="section-row"
                    *ngIf="addEditType == addEditTypeEnum.add && mockIsEditDiaphragmDesign">
                    <span class="local-steel-material-disclaimer"
                        l10n="Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer"></span>
                </div>
            </div>

            <ng-container *ngIf="selectedModuleDesignInfo == null">
                <div *ngIf="deckingEnabled && isDeckingType">
                    <decking-diaphragm-design-settings
                        (loadingSettings)="getLoadingDeckingStatus($any($event).detail)"
                        [customName]="nameTextBox.value"
                        [selectedRegion]="regionDropdown.selectedValue"
                        [openAdvancedSettings]="openAdvancedSettings"
                        [documentId]="design.id"
                        [isTemplate]="isTemplate"
                        [addEditType]="addEditType">
                    </decking-diaphragm-design-settings>
                </div>
            </ng-container>
        </div>

        <div #componentInjector></div>
    </div>

    <div class="modal-footer">
        <div class="w-100">
            <div #componentFooterInjector></div>
        </div>

        <button type="button" id="add-edit-design-cancel-button"
            class="modal-button button button-default" l10n="Agito.Hilti.Profis3.AddEditDesign.Cancel" (click)="close()"
            [disabled]="submitted">
        </button>

        <button type="submit" id="add-edit-design-save-button" class="modal-button button button-CTA"
            [disabled]="!formValid || (ngForm.enabled && !ngForm.valid) || submitted">
            <pe-loading-button [loading]="submitted"
                [text]="'Agito.Hilti.Profis3.AddEditDesign.Save' | l10n:localization.selectedLanguage">
            </pe-loading-button>
        </button>
    </div>
</form>
