import { from, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpResponse, HttpResponseBase
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { logApiServiceCall } from '@profis-engineering/pe-ui-common/helpers/remote-logging-helper';
import { isValidUrl } from '@profis-engineering/pe-ui-common/helpers/url-helper';
import { ApiHttpRequest } from '@profis-engineering/pe-ui-common/services/api.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { FeatureVisibilityService } from '../services/feature-visibility.service';
import { LocalizationService } from '../services/localization.service';
import { LoggerService } from '../services/logger.service';
import { ModalService } from '../services/modal.service';
import { ModulesService } from '../services/modules.service';
import { RoutingService } from '../services/routing.service';
import { UserService } from '../services/user.service';
import { CustomURLEncoder } from '../url-encoder/custom-url-encoder';

@Injectable()
export class MainInterceptor implements HttpInterceptor {
    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly modalService: ModalService,
        private readonly userService: UserService,
        private readonly localizationService: LocalizationService,
        private readonly routingService: RoutingService,
        private readonly modulesService: ModulesService,
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly logger: LoggerService,
    ) { }

    public intercept(req: ApiHttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const start = new Date();
        const apiHeaders = this.userService.getHeaders(req.url, req.forceIncludeAuthenticationHeaders);

        if (apiHeaders == null) {
            // returns a promise that will never get resolved so it's fine if we cast it to any
            return from(this.authenticationService.login(this.routingService.currentPath)) as any;
        }

        // default headers
        let reqHeaders = req.headers;

        if (!reqHeaders.has('Accept')) {
            reqHeaders = reqHeaders
                .set('Accept', 'application/json;odata=verbose');
        }

        // merge req.headers with apiHeaders
        for (const key in apiHeaders) {
            reqHeaders = reqHeaders.set(key, apiHeaders[key]);
        }

        // default params codec https://github.com/angular/angular/issues/11058
        let reqParams = req.params;
        if (reqParams != null) {
            const paramsObject: Record<string, string[]> = {};
            for (const key of reqParams.keys()) {
                paramsObject[key] = reqParams.getAll(key);
            }

            reqParams = new HttpParams({
                fromObject: paramsObject,
                encoder: new CustomURLEncoder()
            });
        }

        // new request
        const orgReq = req;
        req = this.cloneApiHttpRequest(req, { headers: reqHeaders, params: reqParams });

        // invoke request
        try {
            return this.invoke(req, next, 1);
        }
        catch (error) {
            return from(this.handleError(req, error, start))
                .pipe(
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    map(_ => { throw error; })
                );
        }
    }


    private invoke(req: ApiHttpRequest<any>, next: HttpHandler, retryCount = 0): Observable<HttpEvent<any>> {
        const start = new Date();
        return next.handle(req)
            .pipe(
                // on success
                tap(data => {
                    if (data instanceof HttpResponse) {
                        this.handleSuccess(req, data, start);
                    }
                }),
                // on error
                catchError(error => {
                    // Create and return new Observable, which handles the error (async) and, if needed, invokes the same request again.
                    return from(this.handleError(req, error, start))
                        .pipe(
                            mergeMap(newReq => {
                                // If we have newReq, this indicates we should retry invoke with newReq. But only if we did not already exceed allowed retries.
                                if (newReq != null && retryCount > 0) {
                                    return this.invoke(newReq, next, retryCount - 1);
                                }
                                return throwError(() => error);
                            })
                        );
                })
            );
    }

    private handleSuccess(request: ApiHttpRequest, response: HttpResponse<any>, start: Date) {
        if (!request.logCallToRemoteLogging || !this.featureVisibilityService.isFeatureEnabled('DataDogRequestLogging')){
            return;
        }

        logApiServiceCall(this.logger, LogType.info, this.userService.authentication, start, request, response, true);
    }

    private async handleError(req: ApiHttpRequest<any>, error: unknown, start: Date): Promise<ApiHttpRequest<any>> {
        // logging additional data on error
        logApiServiceCall(this.logger, LogType.error, this.userService.authentication, start, req, error, false);

        const httpResponse = error instanceof HttpResponseBase ? error : undefined;

        if (httpResponse?.status === 401) {
            // logout on AuthenticationRequired urls
            if (
                environment.authenticationRequired.some((url) => isValidUrl(url) && req.url.startsWith(url))
                ||
                this.modulesService.serviceRequiresAuthentication(req.url)
            ) {
                if (httpResponse instanceof HttpErrorResponse && httpResponse.error?.reason === 'LoginOtherDevice') {
                    this.modalService.openUnauthorizedAccess();
                }
                else if (!req.skipRefreshToken){
                    const tokenExtended = await this.authenticationService.tryExtendToken();
                    if (!tokenExtended) {
                        // returns a promise that will never get resolved and redirects to login
                        await this.authenticationService.login(this.routingService.currentPath);
                    }
                    else {
                        // token is extended, user is authorized and can continue - we will retry the request
                        // however, we must replace token in request header with new one, and for this we need new request instance
                        const newHeaders = req.headers.set('Authorization', `Bearer ${this.userService.authentication.accessToken}`);
                        const newReq = this.cloneApiHttpRequest(req, { headers: newHeaders });
                        return newReq;
                    }
                }
            }
        }

        if (req.supressErrorMessage === true) {
            return null;
        }

        if (httpResponse?.status === 403) {
            // Show error message that access to the content is forbidden
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.Main.Forbidden.Popup.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.Main.Forbidden.Popup.Message')
            );
        }
        else if (httpResponse?.status === 0 && !this.userService.isAuthenticated) {
            // Don't show errors
            return null;
        }

        // show error message (ignore canceled, unauthenticated and forbidden requests)
        const requestId = req.headers.get('HC-TransactionId') ?? '';

        this.modalService.openAlertServiceError({
            response: error,
            correlationId: requestId
        });

        return null;
    }

    private cloneApiHttpRequest(req: ApiHttpRequest<any>, update: {
            headers?: HttpHeaders;
            params?: HttpParams;
        }) {
        const orgReq = req;
        req = req.clone(update);

        // we need to copy every property in ApiHttpRequest by hand
        req.supressErrorMessage = orgReq.supressErrorMessage;
        req.forceIncludeAuthenticationHeaders = orgReq.forceIncludeAuthenticationHeaders;
        req.logCallToRemoteLogging = orgReq.logCallToRemoteLogging;

        return req;
    }
}
